/* eslint-disable no-debugger */
import { ChainId } from '@crocswap/sdk'
import BigNumber from 'bignumber.js'
import { useFoundOnInactiveList } from 'hooks/Tokens'
import { deserializeFarm } from 'state/farms/hooks'
import { SerializedFarm, DeserializedPool, SerializedPool } from 'state/types'
import { deserializeToken } from 'state/user/hooks/helpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'

type UserData =
  | DeserializedPool['userData']
  | {
    allowance: number | string
    stakingTokenBalance: number | string
    stakedBalance: number | string
    pendingReward: number | string
  }

export const transformUserData = (userData: UserData) => {
  return {
    allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
    stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
    stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
    pendingReward: userData ? new BigNumber(userData.pendingReward) : BIG_ZERO,
  }
}

export const transformPool = (pool: SerializedPool): DeserializedPool => {
  const { totalStaked, stakingLimit, userData, stakingToken, earningToken, ...rest } = pool

  return {
    ...rest,
    stakingToken: deserializeToken(stakingToken),
    earningToken: deserializeToken(earningToken),
    userData: transformUserData(userData),
    totalStaked: new BigNumber(totalStaked),
    stakingLimit: new BigNumber(stakingLimit),
  }
}

export const getTokenPricesFromFarm = (farms: SerializedFarm[]) => {
  return farms.reduce((prices, farm) => {
    const quoteTokenAddress = farm.quoteToken.address.toLocaleLowerCase()
    const tokenAddress = farm.token.address.toLocaleLowerCase()
    /* eslint-disable no-param-reassign */
    if (!prices[quoteTokenAddress]) {
      prices[quoteTokenAddress] = new BigNumber(farm.quoteTokenPriceBusd).toNumber()
    }
    if (!prices[tokenAddress]) {
      prices[tokenAddress] = new BigNumber(farm.tokenPriceBusd).toNumber()
    }
    /* eslint-enable no-param-reassign */
    return prices
  }, {})
}

export const getLpPricesFromFarm = (farms: SerializedFarm[]) => {
  return farms.reduce((prices, farm) => {
    const dfarm = deserializeFarm(farm)

    const farmTokenPriceInUsd = new BigNumber(farm.tokenPriceBusd)
    let lpTokenPrice = BIG_ZERO

    if (dfarm.lpTotalSupply.gt(0) && dfarm.lpTotalInQuoteToken.gt(0)) {
      // Total value of base token in LP
      const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(dfarm.tokenAmountTotal)
      // Double it to get overall value in LP
      const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
      // Divide total value of all tokens, by the number of LP tokens
      const totalLpTokens = getBalanceAmount(dfarm.lpTotalSupply)
      lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
      // eslint-disable-next-line no-param-reassign
      prices[farm.lpAddresses[ChainId.MAINNET].toLocaleLowerCase()] = lpTokenPrice.toNumber()
    }
    return prices
  }, {})
}

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
  }
  return res;
}

const tokenPriceMap = {};
export const TokenPriceFromAPI = async (mmfPrice, croPrice, metfPrice) => {
  let lastUpdated = Date.now();

  // kept lowercase explicitly
  const tokenMaps = {
    '0x97749c9b61f878a880dfe312d2594ae07aed7656': 'random', // MMF-usdc
    '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23': 'random', // wcro-usdc
    '0x3a1138075bd97a33f23a87824b811146fa44288e': 'random', // Mino
    '0x3405a1bd46b85c5c029483fbecf2f3e611026e45': 'random',
    '0xa0c3c184493f2fae7d2f2bd83f195a1c300fa353': 'random',
    '0xcc57f84637b441127f2f74905b9d99821b47b20c': 'random',
    '0x7d30c36f845d1dee79f852abf3a8a402fadf3b53': 'random',
    '0xa57a7b5f8067156c2dbb06cf5e4d8acef17aee64': 'random',
    '0xc4a174ccb5fb54a6721e11e0ca961e42715023f9': 'random',
    '0x654bac3ec77d6db497892478f854cf6e8245dca9': 'random',
    '0x8efbaa6080412d7832025b03b9239d0be1e2aa3b': 'random',
    '0x57d06bb1e3b60c875cd3a4445a53217f9b44d390': 'random',
    '0x49fb98f9b4a3183cd88e7a115144fdf00fa6fb95': 'random',
    '0x43e90012a3d69AeBdc2750424b41655776c6D598': 'random',
    '0xa60943a1b19395c999ce6c21527b6b278f3f2046': 'random',
    '0x212331e1435A8df230715dB4C02B2a3A0abF8c61': 'random',
    '0x2258459C937D0b4Cb58289718c900b43c9C4813a': 'random',
    '0x0804702a4E749d39A35FDe73d1DF0B1f1D6b8347': 'random',
    '0x7b8aD6d7560FAcd1959cfb4b4163D7d297c4bFc0': 'random',
    '0x383627CaeC2CE3b36793c34B576B2e97BEDA0466': 'random',
    '0xD8eB4845B094e05f4d01d7BB3468238076F744f1': 'random',
    '0x47A9D630dc5b28F75d3AF3be3AAa982512Cd89Aa': 'random',
    '0x6D6D6ba0c7e7DBaFffeC82b1ddF92e271650a63A': 'random',
    '0x00d7699b71290094ccb1a5884cd835bd65a78c17': 'random',
    '0x22a31bd4cb694433b6de19e0acc2899e553e9481': 'random',
    '0x859a50979fdb2a2fd8ba1adcc66977c6f6b1cd5b': 'random',
    '0x8f857af6ea31447bb502fe0e3f4e4340cdfcfc6c': 'random'
  }


  if (Object.keys(tokenPriceMap).length > 0 && (Date.now() < lastUpdated + 0.5 * 60 * 1000)) {
    // console.log(tokenPriceMap)
    return tokenPriceMap
  }
  // const prices = {}
  const allTokens = Object.keys(tokenMaps);
  const subArrayAllTokens = sliceIntoChunks(allTokens, Math.ceil(allTokens.length / 5));

  const data = await Promise.all(subArrayAllTokens.map(x=> fetch(`https://api.dexscreener.com/latest/dex/tokens/${x.join(',')}`)));

  const responses = (await Promise.all(data.map(r => r.json()))).map(x => x.pairs).flat();

  responses.forEach((cur) => {
    if (!tokenPriceMap[cur.baseToken.address.toLocaleLowerCase()]) {
      tokenPriceMap[cur.baseToken.address.toLocaleLowerCase()] = new BigNumber(cur.priceUsd).toNumber();
    }
    // console.log(cur.baseToken.address.toLocaleLowerCase(), cur.priceUsd)
  });

  tokenPriceMap["0x1066c6753ffaf8540f691643a6d683e23599c4ab"] = tokenPriceMap["0x3a1138075bd97a33f23a87824b811146fa44288e"] // wsMino
  tokenPriceMap["0xb966b5d6a0fcd5b373b180bbe072bbfbbee10552"] = tokenPriceMap["0x47a9d630dc5b28f75d3af3be3aaa982512cd89aa"] // xARGO
  tokenPriceMap["0xbdc8fd437c489ca3c6da3b5a336d11532a532303"] = tokenPriceMap["0x22a31bd4cb694433b6de19e0acc2899e553e9481"] // MMF (P)

  tokenPriceMap["0xf8acf86194443dcde55fc5b9e448e183c290d8cb"] = tokenPriceMap["0x859a50979fdb2a2fd8ba1adcc66977c6f6b1cd5b"] // MMF (P)
  lastUpdated = Date.now()

  return tokenPriceMap
}


  // return fetch('https://api.mm.finance/api/pairs')
  //   .then((res) => {
  //     return res.json()
  //   })
  //   .then((x) => {
  //     // const { data, error } = x
  //     const data = x
  //     // if (error) throw new Error('API failed')
  //     const prices = {
  //     }

  //     Object.keys(tokenMaps).forEach((token) => {
  //       // LP
  //       // debugger
  //       const lpData = data[tokenMaps[token]]
  //       if (!lpData) return

  //       if (lpData && token === lpData.pair_address.toLocaleLowerCase()) {
  //         prices[token] = new BigNumber(lpData.price).toNumber()
  //         return
  //       }


  //       // base adderss
  //       if (token === lpData.base_address.toLocaleLowerCase()) {
  //         // Staking LP
  //         if (token === '0x3a1138075bd97a33f23a87824b811146fa44288e') {
  //           prices['0x1066c6753ffaf8540f691643a6d683e23599c4ab'] = new BigNumber(
  //             lpData.price,
  //           ).toNumber()
  //         }

  //         // BISON-CRO
  //         if (token === "0x3405a1bd46b85c5c029483fbecf2f3e611026e45") {
  //           prices[token] = new BigNumber(croPrice).multipliedBy(new BigNumber(
  //             lpData.price,
  //           )).toNumber()

  //           return
  //         }

  //         if (token === "0x654bac3ec77d6db497892478f854cf6e8245dca9") {
  //           prices[token] = new BigNumber(mmfPrice).multipliedBy(new BigNumber(
  //             lpData.price,
  //           )).toNumber()

  //           return
  //         }

  //         prices[token] = new BigNumber(lpData.price).toNumber()
  //       }
  //       // quote address
  //       else {
  //         if (token === '0xa0c3c184493f2fae7d2f2bd83f195a1c300fa353' || token === "0x3405a1bd46b85c5c029483fbecf2f3e611026e45") {
  //           prices[token] = new BigNumber(mmfPrice).dividedBy(new BigNumber(
  //             lpData.price,
  //           )).toNumber()

  //           return
  //         }

  //         if (token === '0xea6d09e31c4fcfcf76f89c47629af294955a4599') {
  //           console.log(metfPrice, lpData.price)
  //           prices[token] = new BigNumber(lpData.price).dividedBy(new BigNumber(
  //             metfPrice
  //           )).toNumber()

  //           return
  //         }

  //         // MOON-CRO
  //         if (token === "0x7d30c36f845d1dee79f852abf3a8a402fadf3b53" || token === '0xa57a7b5f8067156c2dbb06cf5e4d8acef17aee64') {
  //           prices[token] = new BigNumber(croPrice).multipliedBy(new BigNumber(1 / lpData.price)).toNumber()
  //           return
  //         }

  //         if (token === "0xc4a174ccb5fb54a6721e11e0ca961e42715023f9") {
  //           prices[token] = new BigNumber(croPrice).multipliedBy(new BigNumber(1 / lpData.price)).toNumber()
  //           return
  //         }

  //         prices[token] = new BigNumber(1 / lpData.price).toNumber()
  //       }
  //     })

  //     tokenPriceMap = { ...tokenPriceMap, ...prices }
  //     return tokenPriceMap
  //   })
  //   .catch((err) => {
  //     console.error(err)
  //     return {}
  //   })

  // return { allLPData, tokenPriceMap }

  // return {}
