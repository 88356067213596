import React from 'react'
import {
  Tag,
  VerifiedIcon,
  CommunityIcon,
  RefreshIcon,
  AutoRenewIcon,
  TagProps,
  TimerIcon,
  BlockIcon,
  VoteIcon,
  Text,
  SvgProps
} from '@crocswap/uikit'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'contexts/Localization'
import { Variant } from '@crocswap/uikit/dist/components/Tag/types'

const CoreTag: React.FC<TagProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Tag variant="secondary" outline startIcon={<VerifiedIcon width="18px" color="secondary" mr="4px" />} {...props}>
      {t('Core')}
    </Tag>
  )
}

const CommunityTag: React.FC<TagProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Tag variant="failure" outline startIcon={<CommunityIcon width="18px" color="failure" mr="4px" />} {...props}>
      {t('Community')}
    </Tag>
  )
}

const DualTag: React.FC<TagProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Tag variant="textSubtle" outline {...props}>
      {t('Dual')}
    </Tag>
  )
}

const ManualPoolTag: React.FC<TagProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Tag variant="secondary" outline startIcon={<RefreshIcon width="18px" color="secondary" mr="4px" />} {...props}>
      {t('Manual')}
    </Tag>
  )
}

const CompoundingPoolTag: React.FC<TagProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Tag variant="success" outline startIcon={<AutoRenewIcon width="18px" color="success" mr="4px" />} {...props}>
      {t('Auto')}
    </Tag>
  )
}

const VoteNowTag: React.FC<TagProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Tag variant="success" startIcon={<VoteIcon width="18px" color="success" mr="4px" />} {...props}>
      {t('Vote Now')}
    </Tag>
  )
}

const SoonTag: React.FC<TagProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Tag variant="binance" startIcon={<TimerIcon width="18px" color="success" mr="4px" />} {...props}>
      {t('Soon')}
    </Tag>
  )
}

const ClosedTag: React.FC<TagProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Tag variant="textDisabled" startIcon={<BlockIcon width="18px" color="textDisabled" mr="4px" />} {...props}>
      {t('Closed')}
    </Tag>
  )
}

export const tagTypes = {
  SUCCESS: 'success',
  WARNING: 'warning',
  FAILURE: 'failure',
  PRIMARY: 'primary',
} as const

export const tagVariants = {
  [tagTypes.SUCCESS]: {
    main: 'successLight',
    text: 'success',
  },
  [tagTypes.WARNING]: {
    main: 'warningLight',
    text: 'warning',
  },
  [tagTypes.FAILURE]: {
    main: 'failureLight',
    text: 'failure',
  },
  [tagTypes.PRIMARY]: {
    main: 'primaryLight',
    text: 'primary',
  },
}

export type TagVariant = typeof tagTypes[keyof typeof tagTypes]

const TagComponent: React.FC<
  TagProps & {
  title: string
  variant: TagVariant
  fill?: string
  prepend?: React.FC<SvgProps>
  append?: React.FC<SvgProps>
}
  > = (props) => {
  const { t } = useTranslation()
  const { title, variant, prepend: Prepend, append: Append } = props
  const { theme } = useTheme()
  return (
    <Tag
      variant={tagVariants[variant].main as Variant}
      startIcon={Prepend && <Prepend width="18px" mr="4px" color={theme.colors[tagVariants[variant].text]} />}
      endIcon={Append && <Append width="18px" mr="4px" color={theme.colors[tagVariants[variant].text]} />}
    >
      <Text fontSize="13px" color={theme.colors[tagVariants[variant].text]} bold>
        {t(title)}
      </Text>
    </Tag>
  )
}

export { CoreTag, CommunityTag, DualTag, ManualPoolTag, CompoundingPoolTag, VoteNowTag, SoonTag, ClosedTag, TagComponent }
