import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'
import { farmsConfig } from 'config/constants'
import useRefresh from 'hooks/useRefresh'
import { deserializeToken } from 'state/user/hooks/helpers'
import tokens from 'config/constants/tokens'
import { useTokenBalance } from 'state/wallet/hooks'
import { ChainId, Token } from '@crocswap/sdk'
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, nonArchivedFarms } from '.'
import { DeserializedFarm, DeserializedFarmsState, DeserializedFarmUserData, SerializedFarm, State } from '../types'

const deserializeFarmUserData = (farm: SerializedFarm): DeserializedFarmUserData => {
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
    farmNftSlots: farm.userData.farmNftSlots,
    farmNftTokenIds: farm.userData.farmNftTokenIds,
    farmBoostRate: farm.userData.farmBoostRate,
  }
}

export const deserializeFarm = (farm: SerializedFarm): DeserializedFarm => {
  const {
    lpAddresses,
    lpSymbol,
    pid,
    dual,
    multiplier,
    isCommunity,
    quoteTokenPriceBusd,
    tokenPriceBusd,
    quoteTokenAmountMc,
    isStableSwap,
    numTokens,
    isV2,
  } = farm

  return {
    lpAddresses,
    lpSymbol,
    pid,
    dual,
    multiplier,
    isCommunity,
    quoteTokenPriceBusd,
    tokenPriceBusd,
    isStableSwap,
    numTokens,
    token: deserializeToken(farm.token),
    quoteToken: deserializeToken(farm.quoteToken),
    userData: deserializeFarmUserData(farm),
    tokenAmountTotal: farm.tokenAmountTotal ? new BigNumber(farm.tokenAmountTotal) : BIG_ZERO,
    lpTotalInQuoteToken: farm.lpTotalInQuoteToken ? new BigNumber(farm.lpTotalInQuoteToken) : BIG_ZERO,
    lpTotalSupply: farm.lpTotalSupply ? new BigNumber(farm.lpTotalSupply) : BIG_ZERO,
    tokenPriceVsQuote: farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO,
    poolWeight: farm.poolWeight ? new BigNumber(farm.poolWeight) : BIG_ZERO,
    quoteTokenAmountMc,
    isV2,
  }
}

export const usePollFarmsPublicData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

export const usePollFarmsWithUserData = (includeArchive = false, isV2: boolean) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.filter((farm) => farm.isV2 === isV2).map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync())

    if (account) {
      dispatch(fetchFarmUserDataAsync({ account, pids, isV2 }))
    }
  }, [includeArchive, dispatch, slowRefresh, account, isV2])
}

/**
 * Fetches the "core" farm data used globally
 * 1 = MMF-CRO LP
 * 4 = CRO-USDC LP
 */
export const usePollCoreFarmData = () => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
  }, [dispatch, fastRefresh])
}

export const useFarms = (getExtend?: boolean, exceptLPAddress?: string[]): DeserializedFarmsState => {
  const farms = useSelector((state: State) => state.farms)
  let arr = farms.data
  if (getExtend) {
    arr = farms.data.concat(farms.governanceExtendLps)
  }
  if (exceptLPAddress) {
    arr = arr.filter(farm => !exceptLPAddress.includes(farm.lpAddresses[ChainId.MAINNET]))
  }
  const deserializedFarmsData = arr.map(deserializeFarm)

  const { loadArchivedFarmsData, userDataLoaded } = farms
  return {
    loadArchivedFarmsData,
    userDataLoaded,
    data: deserializedFarmsData,
  }
}

export const useFarmFromPid = (pid: number, isV2: boolean): DeserializedFarm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid && f.isV2 === isV2))
  return deserializeFarm(farm)
}

export const useFarmFromLpSymbol = (lpSymbol: string, isV2: boolean): DeserializedFarm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol && f.isV2 === isV2))
  return deserializeFarm(farm)
}

export const useFarmUser = (pid, isV2: boolean): DeserializedFarmUserData => {
  const { userData } = useFarmFromPid(pid, isV2)
  const { allowance, tokenBalance, stakedBalance, earnings, farmNftSlots, farmNftTokenIds, farmBoostRate } = userData
  return {
    allowance,
    tokenBalance,
    stakedBalance,
    earnings,
    farmNftSlots,
    farmNftTokenIds,
    farmBoostRate,
  }
}

// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid: number, isV2: boolean): BigNumber => {
  const farm = useFarmFromPid(pid, isV2)
  return farm && new BigNumber(farm.tokenPriceBusd)
}

export const useLpTokenPrice = (symbol: string, isV2: boolean) => {
  const farm = useFarmFromLpSymbol(symbol, isV2)
  const farmTokenPriceInUsd = useBusdPriceFromPid(farm.pid, isV2)
  let lpTokenPrice = BIG_ZERO

  if (farm.lpTotalSupply.gt(0) && farm.lpTotalInQuoteToken.gt(0)) {
    if (symbol.includes('3MM')) {
      // console.log('3MM', symbol)
      lpTokenPrice = farm.tokenPriceVsQuote
      return lpTokenPrice
    }
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(farm.tokenAmountTotal)
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(farm.lpTotalSupply)
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
  }

  if (symbol.includes('bcroMM')) {
    // Divide by 2 because the earlier portion multiplied by 2 (cos that's the formula for a standard LP)
    return lpTokenPrice.div(2)
  }

  return lpTokenPrice
}

// /!\ Deprecated , use the BUSD hook in /hooks

export const useFarmFromLpPair = (token: Token, quoteToken: Token): DeserializedFarm => {
  const farm = useSelector((state: State) =>
    state.farms.data.find((f) => f.token.symbol === token.symbol && f.quoteToken.symbol === quoteToken.symbol),
  )
  return deserializeFarm(farm)
}

export const usePriceMMFBusd = (): BigNumber => {
  const mmfCroFarm = useFarmFromPid(1, false)
  const croUSD = useFarmFromPid(4, false)

  const cakePriceBusdAsString = mmfCroFarm.tokenPriceVsQuote
  const croPrice = croUSD.tokenPriceBusd

  return useMemo(() => {
    return new BigNumber(cakePriceBusdAsString).multipliedBy(new BigNumber(croPrice))
  }, [cakePriceBusdAsString, croPrice])
}

export const usePriceMETFBusd = (): BigNumber => {
  const metfMMF = useFarmFromPid(27, false)
  const mmfUSD = usePriceMMFBusd()

  const metfvsMMF = metfMMF.tokenPriceVsQuote
  // const croPrice = croUSD.tokenPriceBusd

  const metfPriceBusd = useMemo(() => {
    return new BigNumber(metfvsMMF).multipliedBy(new BigNumber(mmfUSD))
  }, [metfvsMMF, mmfUSD])

  return metfPriceBusd
}

// return new BigNumber(0.06)

// const mmfCroFarm = useFarmFromLpPair(tokens.mmf, tokens.wcro) // FIXME pid for croc/WCRO
// const crocPriceUsdcAsString = mmfCroFarm.tokenPriceBusd

// const crocPriceUsdc = useMemo(() => {
//   return new BigNumber(crocPriceUsdcAsString)
// }, [crocPriceUsdcAsString])

// return crocPriceUsdc

// export const usePriceVvsUsdc = (): BigNumber => {
//   // const vvsCroFarm = useFarmFromPid(3) // FIXME pid for VVS/WCRO

//   // const farms = useSelector((state: State) => state.farms)
//   // const prices = getTokenPricesFromFarm(farms.data)
//   // return prices[serializeTokens().vvs.address] ?? new BigNumber(0);
// }

export const useMmfBurnt = (): string => {
  // const { fastRefresh } = useRefresh()
  // const [squidBurnt, setSquidBurnt] = useState('0')
  const balance = useTokenBalance('0x61c20e2E1ded20856754321d585f7Ad28e4D6b27', tokens.mmf)

  // useMemo(async () => {
  //   const amount = await tokens.pendingSquid('15', '0x61c20e2E1ded20856754321d585f7Ad28e4D6b27')
  //   setSquidBurnt(amount.toString())
  //   console.log(fastRefresh)
  // }, [masterchef, fastRefresh])

  return balance?.toFixed(2)
}
