import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GovernanceState, GovernanceUserBalance } from 'state/types'
import tokens from 'config/constants/tokens'
import { getTokenPricesFromFarm, TokenPriceFromAPI } from 'state/pools/helpers'
import {
  fetchTokenGaugeAPR,
  fetchTokenGaugePast,
  fetchUserVoted,
  fetchUserVotedPastEpoch,
} from './actions'

const initialState: GovernanceState = {
  data: {
    isLoading: true,
    currentEpoch: 0,
    totalVEMMFHolder: 0,
    voterCount: 0,
    bribeInfo: {},
  },
  pastEpochData: {
    isLoading: true,
    currentEpoch: 0,
    totalVEMMFHolder: 0,
    voterCount: 0,
    bribeInfo: {},
  },
  pendingReward: {
    isLoading: true,
    pendingReward: {},
  },
  userBalance: {
    isLoading: true,
    balance: 0,
    tokenWeights: {},
  },
  userBalancePastEpoch: {
    isLoading: true,
    balance: 0,
    tokenWeights: {},
  },
  userDataLoading: false,
  isShowingPastEpoch: false,
}

const fetchCovalenthq = async (pageNumber = 11) => {
  try {
    const response = await fetch(
      `https://api.covalenthq.com/v1/25/tokens/0xe806Fb9c8a1728203514b953117ACBA2a5e033Cc/token_holders/?quote-currency=USD&format=JSON&page-number=${pageNumber}&key=ckey_a6d253cad3b54e0bb1fe4aebbc5`,
    )
    const data = await response.json()
    if (data.data.pagination.has_more) {
      return fetchCovalenthq(pageNumber + 1)
    }
    window.localStorage.setItem(
      'covalenthq',
      JSON.stringify({
        currentPage: pageNumber,
        lastCall: new Date().getTime(),
      }),
    )
    return (pageNumber - 1) * 100 + data.data.items.length
  } catch (e) {
    console.log(e)
    return (pageNumber - 1) * 100
  }
}

// Thunks
export const fetchTokenGaugeAPRData = () => async (dispatch, getState) => {
  let currentPageCovalenthq
  let totalVEMMFHolder
  if (window.localStorage.getItem('covalenthq')) {
    const parsedCovalenthqData = JSON.parse(window.localStorage.getItem('covalenthq'))
    if (new Date().getTime() - parsedCovalenthqData.lastCall >= 60000) {
      currentPageCovalenthq = parsedCovalenthqData.currentPage
    } else {
      totalVEMMFHolder = parseInt(window.localStorage.getItem('totalVEMMFHolder'))
    }
  } else {
    currentPageCovalenthq = 9
  }
  if (currentPageCovalenthq) {
    totalVEMMFHolder = await fetchCovalenthq(parseInt(window.localStorage.getItem('currentPageCovalenthq') || '11'))
    window.localStorage.setItem('totalVEMMFHolder', totalVEMMFHolder.toString())
  }
  const tokenGaugeAPR = await fetchTokenGaugeAPR()
  const tokenPriceMap = await TokenPriceFromAPI(0, 0, 0)
  const mmfPrice = tokenPriceMap[tokens.mmf.address.toLowerCase()]

  const farmData = getState().farms.data
  const prices = await TokenPriceFromAPI(0, 0, 0)
  const pricesFromFarm = getTokenPricesFromFarm(farmData)
  Object.keys(tokenGaugeAPR.bribeInfo).forEach((token) => {
    const { bribeTokenAddress } = tokenGaugeAPR.bribeInfo[token]
    const bribeAmountInUsd =
      tokenGaugeAPR.bribeInfo[token].bribeAmount *
        (pricesFromFarm[bribeTokenAddress.toLowerCase()] || prices[bribeTokenAddress.toLowerCase()] || 0)
    let yearlyAPR = 0
    if (mmfPrice) {
      const votingTVL = tokenGaugeAPR.bribeInfo[token].epochTokenBalance * mmfPrice
      const yearlyBribeUsd = (bribeAmountInUsd / 14) * 365
      yearlyAPR = (yearlyBribeUsd / votingTVL) * 100
    }

    tokenGaugeAPR.bribeInfo[token].tokenPrice = pricesFromFarm[token] || pricesFromFarm[token.toLowerCase()] || prices[token.toLowerCase()]
    tokenGaugeAPR.bribeInfo[token].bribeAmountInUsd = bribeAmountInUsd
    tokenGaugeAPR.bribeInfo[token].yearlyAPR = parseFloat(yearlyAPR?.toFixed(2))
  })
  tokenGaugeAPR.totalVEMMFHolder = totalVEMMFHolder

  dispatch(setTokenGaugeAPRData(tokenGaugeAPR))
}

// Thunks
export const fetchPastEpochData = (selectedEpoch: number) => async (dispatch, getState) => {
  const tokenGaugeAPR = await fetchTokenGaugePast(selectedEpoch)

  const farmData = getState().farms.data
  const pricesFromFarm = getTokenPricesFromFarm(farmData)
  Object.keys(tokenGaugeAPR.bribeInfo).forEach((token) => {
    const bribeAmountInUsd =
      tokenGaugeAPR.bribeInfo[token].bribeAmount *
        pricesFromFarm[tokenGaugeAPR.bribeInfo[token].bribeTokenAddress.toLowerCase()] || 0

    tokenGaugeAPR.bribeInfo[token].tokenPrice = pricesFromFarm[token]
    tokenGaugeAPR.bribeInfo[token].bribeAmountInUsd = bribeAmountInUsd
  })

  dispatch(setPastEpochData(tokenGaugeAPR))
}

export const fetchUserVotedData = createAsyncThunk<
  GovernanceUserBalance,
  { account: string; votingsContract; selectedEpoch?: number }
>('governance/fetchUserVotedData', async ({ account, votingsContract }, { dispatch }) => {
  dispatch(setFetchingFlag())
  return fetchUserVoted(account, votingsContract)
})

export const fetchUserVotedPasEpochData = createAsyncThunk<
  GovernanceUserBalance,
  { account: string; votingsContract; selectedEpoch?: number }
>('governance/fetchUserVotedPasEpochData', async ({ account, votingsContract, selectedEpoch }) => {
  return  fetchUserVotedPastEpoch(account, votingsContract, selectedEpoch)
})

export const GovernanceSlice = createSlice({
  name: 'Governance',
  initialState,
  reducers: {
    setTokenGaugeAPRData: (state, action) => {
      state.data = action.payload
    },
    setPastEpochData: (state, action) => {
      state.pastEpochData = action.payload
    },
    setFetchingFlag: (state) => {
      state.userDataLoading = true
    },
    setShowingPastEpoch: (state, action) => {
      state.isShowingPastEpoch = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserVotedData.fulfilled, (state, action: PayloadAction<GovernanceUserBalance>) => {
      const data = action.payload
      data.isLoading = false
      state.userBalance = data
      state.userDataLoading = false
    })
    builder.addCase(fetchUserVotedData.rejected, (state) => {
      state.userDataLoading = false
    })
    builder.addCase(fetchUserVotedPasEpochData.fulfilled, (state, action: PayloadAction<GovernanceUserBalance>) => {
      const data = action.payload
      state.userBalancePastEpoch = {
        isLoading: false,
        balance: data.balance,
        tokenWeights: data.tokenWeights
      }
      state.pendingReward = {
        isLoading: false,
        pendingReward: data.resultPendingRewards
      }
    })
  },
})

export const { setTokenGaugeAPRData, setPastEpochData, setFetchingFlag, setShowingPastEpoch } = GovernanceSlice.actions

export default GovernanceSlice.reducer
