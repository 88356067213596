import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getMasterchefContract } from 'utils/contractHelpers'
import { getAddress } from 'utils/addressHelpers'
import { simpleRpcProvider } from 'utils/providers'
import BigNumber from 'bignumber.js'

// Pool 0, Croc / Croc is a different kind of contract (master chef)
// CRO pools use the native CRO token (wrapping ? unwrapping is done at the contract level)
const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol !== 'CRO')
const bnbPools = poolsConfig.filter((pool) => pool.stakingToken.symbol === 'CRO')
const nonMasterPools = poolsConfig.filter((pool) => pool.sousId !== 0)

export const fetchPoolsAllowance = async (account) => {
  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'allowance',
    params: [account, getAddress(pool.contractAddress)],
  }))

  const allowances = await multicall(erc20ABI, calls)
  return nonBnbPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [`${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`]: new BigNumber(allowances[index]).toJSON(),
    }),
    {},
  )
}

export const fetchUserBalances = async (account) => {
  // Non CRO pools
  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken.address,
    name: 'balanceOf',
    params: [account],
  }))
  const tokenBalancesRaw = await multicall(erc20ABI, calls)
  const tokenBalances = nonBnbPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [`${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`]: new BigNumber(tokenBalancesRaw[index]).toJSON(),
    }),
    {},
  )

  // CRO pools
  const bnbBalance = await simpleRpcProvider.getBalance(account)
  const bnbBalances = bnbPools.reduce(
    (acc, pool) => ({
      ...acc,
      [`${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`]: new BigNumber(bnbBalance.toString()).toJSON(),
    }),
    {},
  )

  return { ...tokenBalances, ...bnbBalances }
}

export const fetchUserStakeBalances = async (account, isV2) => {
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account],
  }))
  const userInfo = await multicall(sousChefABI, calls)
  const stakedBalances = nonMasterPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [`${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`]: new BigNumber(userInfo[index].amount._hex).toJSON(),
    }),
    {},
  )

  // Croc / Croc pool
  const { amount: masterPoolAmount } = await getMasterchefContract(isV2).userInfo('0', account)
  const masterPoolKey = `0+${isV2 ? 'V2' : 'V1'}`
  return { ...stakedBalances, [masterPoolKey]: new BigNumber(masterPoolAmount.toString()).toJSON() }
}

export const fetchUserPendingRewards = async (account, isV2) => {
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [account],
  }))
  const res = await multicall(sousChefABI, calls)
  const pendingRewards = nonMasterPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [`${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`]: new BigNumber(res[index]).toJSON(),
    }),
    {},
  )

  // Croc / Croc pool
  const pendingReward = await getMasterchefContract(isV2).pendingMeerkat('0', account)
  const masterPoolKey = `0+${isV2 ? 'V2' : 'V1'}`

  return { ...pendingRewards, [masterPoolKey]: new BigNumber(pendingReward.toString()).toJSON() }
}
