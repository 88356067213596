import tokens from './tokens'
import { SerializedFarmConfig } from './types'

export const largeCapTokens = [
  tokens.dai.address,
  tokens.weth.address,
  tokens.wbtc.address,
  tokens.cro.address,
  tokens.wcro.address,
  tokens.usdt.address,
  tokens.usdc.address,
  tokens.mmbcro.address,
]

export const governanceExtendLps: SerializedFarmConfig[] = [
  {
    pid: 40,
    isV2: true,
    lpSymbol: 'ICY-bCRO LP',
    lpAddresses: {
      338: '0x02f958cb9774807Cc31290F653ee7b15575778cB',
      25: '0x02f958cb9774807Cc31290F653ee7b15575778cB',
    },
    token: tokens.icy,
    quoteToken: tokens.bcro,
  },
]
