import { Token, ChainId } from '@crocswap/sdk'
import tokens from './tokens'
import farms from './farms'
import { Ifo } from './types'

const mmfCroLPToken = new Token(ChainId.MAINNET, farms[1].lpAddresses[ChainId.MAINNET], 18, farms[1].lpSymbol)

const madbox: Ifo[] = [
  {
    id: 'mad',
    address: '0xC009aA6A1Fe8Fe07bcf485a7E631AA056F638750',
    isActive: true,
    name: 'Mad Bucks (MAD)',
    poolBasic: {
      saleAmount: '1,000,000 MAD',
      raiseAmount: '$500,000 in SVN',
      cakeToBurn: '50% of SVN raised',
      distributionRatio: 0.5,
    },
    poolUnlimited: {
      saleAmount: '1,000,000 MAD',
      raiseAmount: '$500,000 in SVN',
      cakeToBurn: '50% of SVN raised',
      distributionRatio: 0.5,
    },
    description:
      'Mad Bucks is a long term utility token which will form the foundation of Mad Meerkat NFT Ecosystem. The $MAD token will now be the governance token of the MMA world',
    currency: tokens.svn,
    token: tokens.mad,
    startTimestamp: 1648605600,
    endTimestamp: 1648634400,
    campaignId: '2',
    articleUrl: 'https://medium.com/@MMFinance/mad-launchpad-mad-1b572c891174',
    tokenOfferingPrice: '$0.50',
    projectSite: 'https://madmeerkat.io/',
    version: 1,
    loadIfoData: true,
    isV2: false,
  },
  {
    id: 'srv',
    address: '0x19A2E5536f1FeA5fC38e79e8d8fB0F678baCC973',
    isActive: false,
    name: 'Serval Finance (SRV)',
    poolBasic: {
      saleAmount: '75,000 SRV',
      raiseAmount: '$750,000 MMF',
      cakeToBurn: '40% of MMF raised',
      distributionRatio: 0.5,
    },
    poolUnlimited: {
      saleAmount: '75,000 SRV',
      raiseAmount: '$750,000 MMF',
      cakeToBurn: '40% of MMF raised',
      distributionRatio: 0.5,
    },
    description:
      'Serval Finance is the first ever DAO protocol on Cronos that unifies metaverse, gamification and NFT’s with DAO.',
    currency: tokens.mmf,
    token: tokens.srv,
    startTimestamp: 1648274400,
    endTimestamp: 1648296000,
    campaignId: '1',
    articleUrl: 'https://serval-finance.gitbook.io/serval-finance/',
    tokenOfferingPrice: '$10',
    projectSite: 'https://srv.finance/',
    version: 1,
    loadIfoData: true,
    isV2: false,
  },
  // {
  //   id: 'metf',
  //   address: '0x6727E4714b2600c3e537819F70c0ef3b8b26e114',
  //   isActive: false,
  //   name: 'MM ETF (METF)',
  //   poolBasic: {
  //     saleAmount: '50,000 METF',
  //     raiseAmount: '750,000 MMF',
  //     cakeToBurn: '0% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '50,000 METF',
  //     raiseAmount: '750,000 MMF',
  //     cakeToBurn: '0% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `MM ETF is the first decentralized ETF protocol that runs on METF token. The DTF term is thus coined by our team as the DEX Traded Fund, being the first of its kind. Each METF token is backed by a basket of assets.`,
  //   currency: tokens.mmf,
  //   token: tokens.metf,
  //   startTimestamp: 1646798400,
  //   endTimestamp: 1646884800,
  //   campaignId: '9',
  //   articleUrl: 'https://mmfinance.gitbook.io/mm-etf/',
  //   tokenOfferingPrice: '15 MMF',
  //   projectSite: 'https://mmfinance.gitbook.io/mm-etf/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'agora',
  //   address: '0x0971044Ad3AbEdFa8c9f31F55419664d682697Cc',
  //   isActive: false,
  //   name: 'Agora NFT Marketplace (AGORA)',
  //   poolBasic: {
  //     saleAmount: '6,000,000 AGORA',
  //     raiseAmount: '$600,000',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '6,000,000 AGORA',
  //     raiseAmount: '$600,000',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `Agora NFT marketplace is the first of its kind to distribute 100% of its profit back to their token holders. $AGO is also used for voting in the DAGO. All the treasury and the development will be decided through the DAGO, the decentralized governance system for Agora.`,
  //   currency: tokens.mmf,
  //   token: tokens.ago,
  //   startTimestamp: 1645430400,
  //   endTimestamp: 1645452000,
  //   campaignId: '8',
  //   articleUrl: 'https://medium.com/@MMFinance/7th-launchpad-agora-8c5a1f47489e',
  //   tokenOfferingPrice: '$0.1',
  //   projectSite: 'https://agoracro.com/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'betify',
  //   address: '0x2fB07e9106Bd777a5157bC66fBEae085BC2D8508',
  //   isActive: false,
  //   name: 'Gamblefi (BETIFY)',
  //   poolBasic: {
  //     saleAmount: '100,000 BETIFY',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '30% of BETIFY raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '100,000 BETIFY',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '30% of BETIFY raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `GambleFi is the first Rebase DAO featuring a Zero Loss Betting system, where all users have no risk of losing their capital while helping with the exponential growth of the treasury.`,
  //   currency: tokens.mmf,
  //   token: tokens.betify,
  //   startTimestamp: 1644998400,
  //   endTimestamp: 1645020600,
  //   campaignId: '7',
  //   articleUrl: 'https://gamblefi.medium.com/gamblefi-x-mm-finance-launch-a010e6b22c15',
  //   tokenOfferingPrice: '$10',
  //   projectSite: 'https://gamblefi.io',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'svn',
  //   address: '0x4eB5C91A7aC3681DCEC901d8dE8C19E5240A75AE',
  //   isActive: false,
  //   name: 'Savanna Finance (SVN)',
  //   poolBasic: {
  //     saleAmount: '1,500,000 SVN',
  //     raiseAmount: '1,500,000 MMF',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '1,500,000 SVN',
  //     raiseAmount: '1,500,000 MMF',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `An algorithmic stable coin platform with our token $SVN pegged to 1 MMF. Aimed at creating a high-yield, safe farming environment whilst ensuring high liquidity for MMF/SVN at the same time.`,
  //   currency: tokens.mmf,
  //   token: tokens.svn,
  //   startTimestamp: 1644552000,
  //   endTimestamp: 1644638400,
  //   campaignId: '6',
  //   articleUrl: 'https://medium.com/@MMFinance/5th-launchpad-svn-savanna-finance-358d23cf7ba5',
  //   tokenOfferingPrice: '1 MMF',
  //   projectSite: 'https://svn.finance',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'crp',
  //   address: '0x8fD0973Ef39165656D1e5b24C9323d3b4629823b',
  //   isActive: false,
  //   name: 'CRO Predict (CRP)',
  //   poolBasic: {
  //     saleAmount: '200,000 CRP',
  //     raiseAmount: '$500,000',
  //     cakeToBurn: '50% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '200,000 CRP',
  //     raiseAmount: '$500,000',
  //     cakeToBurn: '50% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `CRO Predict is a prediction market maker GameFi on the Cronos Chain, it’s a play-to-earn (predict-to-earn) GameFi. `,
  //   currency: tokens.mmf,
  //   token: tokens.crp,
  //   startTimestamp: 1643014800,
  //   endTimestamp: 1643036400,
  //   campaignId: '5',
  //   articleUrl: 'https://medium.com/@MMFinance/mmfinance-announcing-4th-launchpad-b86eff5f98f6',
  //   tokenOfferingPrice: '$2.5',
  //   projectSite: 'https://cropredict.finance/#',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'croissant',
  //   address: '0x363D1C27d163c91cF3d652d370d4b5C56A7D948E',
  //   isActive: false,
  //   name: 'Croissant games (CROISSANT)',
  //   poolBasic: {
  //     saleAmount: '50,000,000 CROISSANT',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '50,000,000 CROISSANT',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `Unlike traditional games that operate in black boxes, Croissant Games runs on smart contracts that are fair, transparent, non-custodian, and immutable.`,
  //   currency: tokens.mmf,
  //   token: tokens.croissant,
  //   startTimestamp: 1642064400,
  //   endTimestamp: 1642078800,
  //   campaignId: '4',
  //   articleUrl: 'https://medium.com/@MMFinance/514406fab572',
  //   tokenOfferingPrice: '$0.02',
  //   projectSite: 'https://croissant.games/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'mimas',
  //   address: '0x466B8E113a7c3da3871Bbca2698BE1BE2FC6cf0d',
  //   isActive: false,
  //   name: 'MIMAS FINANCE (MIMAS)',
  //   poolBasic: {
  //     saleAmount: '10,000,000 MIMAS',
  //     raiseAmount: '$2,000,000',
  //     cakeToBurn: '$1,000,000',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '10,000,000 MIMAS',
  //     raiseAmount: '$2,000,000',
  //     cakeToBurn: '$1,000,000',
  //     distributionRatio: 0.5,
  //   },
  //   description: `Mimas Finance is an algorithmic money market and liquid staking protocol on the Cronos blockchain. Built by the same team behind Tranquil on the Harmony blockchain, it allows users to supply and borrow assets, while receiving or paying interest in a completely permission-less and decentralized way. Borrowing and lending interest is set based on market demand, and loans are over-collateralized to ensure solvency.`,
  //   currency: mmfCroLPToken,
  //   token: tokens.mimas,
  //   startTimestamp: 1641204000,
  //   endTimestamp: 1641290400,
  //   campaignId: '3',
  //   articleUrl: 'https://medium.com/@MMFinance/mm-finance-2nd-launchpad-mimas-finance-d47fa8901f8a',
  //   tokenOfferingPrice: '$0.2',
  //   projectSite: 'https://mimas.finance/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'mmo',
  //   address: '0x22E5425015E6446c81215Bd32FDdF9B842a6721F',
  //   isActive: false,
  //   name: 'MM Optimizer (MMO)',
  //   poolBasic: {
  //     saleAmount: '625,000 MMO',
  //     raiseAmount: '$2,500,000',
  //     cakeToBurn: '$1,250,000',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '625,000 MMO',
  //     raiseAmount: '$2,500,000',
  //     cakeToBurn: '$1,250,000',
  //     distributionRatio: 0.5,
  //   },
  //   description: `MM Optimizer is the go-to state-of-the-art yield aggregator & optimizer on the Cronos Chain. Within the DeFi ecosystem, the best opportunities to compound your assets can be found within the MMO platform offering a suite of yield-generating products, focused on accessibility, simplicity, optimization and longevity.`,
  //   currency: mmfCroLPToken,
  //   token: tokens.mmo,
  //   startTimestamp: 1640404800,
  //   endTimestamp: 1640491200,
  //   campaignId: '511140000',
  //   articleUrl: 'https://mmfinance.gitbook.io/optimizer',
  //   tokenOfferingPrice: '$4',
  //   projectSite: 'https://vaults.cronosmm.finance/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // // {
  // //   id: 'mmf',
  // //   address: '0x187F71C2A9B64bC0011D6cBf2bcA4aAd56631024',
  // //   isActive: false,
  // //   name: 'MM Finance token (MMF)',
  // //   poolBasic: {
  // //     saleAmount: '4,500,000 MMF',
  //     raiseAmount: '$900,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '4,500,000 MMF',
  //     raiseAmount: '$900,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.5,
  //   },
  //   description: `The first token to be launched is an expansion of the CRONOS ecosystem. $MMF will play a part in cementing our standing amongst the leading DEXes on the CRONOS chain`,
  //   currency: tokens.wcro,
  //   token: tokens.mmf,
  //   startTimestamp: 1638703160,
  //   endTimestamp: 1638875960,
  //   campaignId: '511140000',
  //   articleUrl: 'https://mmfinance.gitbook.io/docs/',
  //   tokenOfferingPrice: 0.2,
  //   projectSite: 'https://cronosmm.finance/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'dar',
  //   address: '0xb6eF1f36220397c434A6B15dc5EA616CFFDF58CE',
  //   isActive: false,
  //   name: 'Mines of Dalarnia (DAR)',
  //   poolBasic: {
  //     saleAmount: '6,000,000 DAR',
  //     raiseAmount: '$450,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '6,000,000 DAR',
  //     raiseAmount: '$450,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.5,
  //   },
  //   currency: tokens.cake,
  //   token: tokens.dar,
  //   releaseBlockNumber: 12335455,
  //   campaignId: '511130000',
  //   articleUrl: 'https://cronosmm.finance/voting/proposal/QmeJenHcbY45eQxLRebzvpNk5qSCrj2wM1t4EAMHotFoJL',
  //   tokenOfferingPrice: 0.075,
  //   version: 2,
  // },
  // {
  //   id: 'dkt',
  //   address: '0x89ab9852155A794e371095d863aEAa2DF198067C',
  //   isActive: false,
  //   name: 'Duelist King (DKT)',
  //   poolBasic: {
  //     saleAmount: '75,000 DKT',
  //     raiseAmount: '$131,250',
  //     cakeToBurn: '$65,625',
  //     distributionRatio: 0.3,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '175,000 DKT',
  //     raiseAmount: '$306,250',
  //     cakeToBurn: '$153,125',
  //     distributionRatio: 0.7,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.dkt,
  //   releaseBlockNumber: 12130750,
  //   campaignId: '511120000',
  //   articleUrl: 'https://cronosmm.finance/voting/proposal/QmTRWdW9a65fAkyJy1wrAJRU548fNMAZhRUDrSxzMDLmwk',
  //   tokenOfferingPrice: 1.75,
  //   version: 2,
  // },
  // {
  //   id: 'kalmar',
  //   address: '0x1aFB32b76696CdF05593Ca3f3957AEFB23a220FB',
  //   isActive: false,
  //   name: 'Kalmar (KALM)',
  //   poolBasic: {
  //     saleAmount: '375,000 KALM',
  //     raiseAmount: '$750,000',
  //     cakeToBurn: '$375,000',
  //     distributionRatio: 0.3,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '875,000 KALM',
  //     raiseAmount: '$2,500,000',
  //     cakeToBurn: '$1,250,000',
  //     distributionRatio: 0.7,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.kalm,
  //   releaseBlockNumber: 7707736,
  //   campaignId: '511110000',
  //   articleUrl: 'https://pancakeswap.medium.com/kalmar-kalm-ifo-to-be-hosted-on-pancakeswap-4540059753e4',
  //   tokenOfferingPrice: 2.0,
  //   version: 2,
  // },
  // {
  //   id: 'hotcross',
  //   address: '0xb664cdbe385656F8c54031c0CB12Cea55b584b63',
  //   isActive: false,
  //   name: 'Hot Cross (HOTCROSS)',
  //   poolBasic: {
  //     saleAmount: '15,000,000 HOTCROSS',
  //     raiseAmount: '$750,000',
  //     cakeToBurn: '$375,000',
  //     distributionRatio: 0.3,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '35,000,000 HOTCROSS',
  //     raiseAmount: '$1,750,000',
  //     cakeToBurn: '$875,000',
  //     distributionRatio: 0.7,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.hotcross,
  //   releaseBlockNumber: 7477900,
  //   campaignId: '511100000',
  //   articleUrl: 'https://pancakeswap.medium.com/hot-cross-hotcross-ifo-to-be-hosted-on-pancakeswap-10e70f1f6841',
  //   tokenOfferingPrice: 0.05,
  //   version: 2,
  // },
  // {
  //   id: 'horizon',
  //   address: '0x6137B571f7F1E44839ae10310a08be86D1A4D03B',
  //   isActive: false,
  //   name: 'Horizon Protocol (HZN)',
  //   poolBasic: {
  //     saleAmount: '3,000,000 HZN',
  //     raiseAmount: '$750,000',
  //     cakeToBurn: '$375,000',
  //     distributionRatio: 0.3,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '7,000,000 HZN',
  //     raiseAmount: '$1,750,000',
  //     cakeToBurn: '$875,000',
  //     distributionRatio: 0.7,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.hzn,
  //   releaseBlockNumber: 6581111,
  //   campaignId: '511090000',
  //   articleUrl: 'https://pancakeswap.medium.com/horizon-protocol-hzn-ifo-to-be-hosted-on-pancakeswap-51f79601c9d8',
  //   tokenOfferingPrice: 0.25,
  //   version: 2,
  // },
  // {
  //   id: 'belt',
  //   address: '0xc9FBedC033a1c479a6AD451ffE463025E92a1d38',
  //   isActive: false,
  //   name: 'Belt (BELT)',
  //   poolUnlimited: {
  //     saleAmount: '150,000 BELT',
  //     raiseAmount: '$3,000,000',
  //     cakeToBurn: '$1,500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.belt,
  //   releaseBlockNumber: 5493919,
  //   campaignId: '511080000',
  //   articleUrl: 'https://pancakeswap.medium.com/belt-fi-belt-ifo-to-be-hosted-on-pancakeswap-353585117e32',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'watch',
  //   address: '0x55344b55C71Ad8834C397E6e08dF5195cF84fe6d',
  //   isActive: false,
  //   name: 'Yieldwatch (WATCH)',
  //   poolUnlimited: {
  //     saleAmount: '8,000,000 WATCH',
  //     raiseAmount: '$800,000',
  //     cakeToBurn: '$400,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.watch,
  //   releaseBlockNumber: 5294924,
  //   campaignId: '511070000',
  //   articleUrl: 'https://pancakeswap.medium.com/yieldwatch-watch-ifo-to-be-hosted-on-pancakeswap-d24301f17241',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'berry',
  //   address: '0x5d028cE3435B2bB9AceBfaC599EEbA1ccD63d7dd',
  //   isActive: false,
  //   name: 'Berry (BRY)',
  //   poolUnlimited: {
  //     saleAmount: '2,000,000 BRY',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.bry,
  //   releaseBlockNumber: 4750968,
  //   campaignId: '511060000',
  //   articleUrl: 'https://pancakeswap.medium.com/berry-bry-ifo-to-be-hosted-on-pancakeswap-b4f9095e9cdb',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'soteria',
  //   address: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
  //   isActive: false,
  //   name: 'Soteria (wSOTE)',
  //   poolUnlimited: {
  //     saleAmount: '1,500,000 wSOTE',
  //     raiseAmount: '$525,000',
  //     cakeToBurn: '$262,500',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.wsote,
  //   releaseBlockNumber: 4086064,
  //   campaignId: '511050000',
  //   articleUrl: 'https://pancakeswap.medium.com/soteria-sota-ifo-to-be-hosted-on-pancakeswap-64b727c272ae',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'helmet',
  //   address: '0xa32509d760ee47Eb2Be96D338b5d69B5FBa4eFEB',
  //   isActive: false,
  //   name: 'Helmet.insure (Helmet)',
  //   poolUnlimited: {
  //     saleAmount: '10,000,000 Helmet',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.helmet,
  //   releaseBlockNumber: 3771926,
  //   campaignId: '511040000',
  //   articleUrl: 'https://pancakeswap.medium.com/1-000-000-helmet-helmet-ifo-to-be-hosted-on-pancakeswap-3379a2a89a67',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'tenet',
  //   address: '0xB922aA19A2603A07C6C9ED6c236618C9bac51f06',
  //   isActive: false,
  //   name: 'Tenet (TEN)',
  //   poolUnlimited: {
  //     saleAmount: '1,000,000 TEN',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.ten,
  //   releaseBlockNumber: 3483883,
  //   campaignId: '511030000',
  //   articleUrl: 'https://pancakeswap.medium.com/tenet-ten-ifo-to-be-hosted-on-pancakeswap-b7e1eb4cb272',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'ditto',
  //   address: '0x570c9eB19553526Fb35895a531928E19C7D20788',
  //   isActive: false,
  //   name: 'Ditto (DITTO)',
  //   poolUnlimited: {
  //     saleAmount: '700,000 DITTO',
  //     raiseAmount: '$630,000',
  //     cakeToBurn: '$315,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.ditto,
  //   releaseBlockNumber: 3279767,
  //   campaignId: '511020000',
  //   articleUrl: 'https://pancakeswap.medium.com/ditto-money-ditto-ifo-to-be-hosted-on-pancakeswap-342da3059a66',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'blink',
  //   address: '0x44a9Cc8463EC00937242b660BF65B10365d99baD',
  //   isActive: false,
  //   name: 'BLINk (BLK)',
  //   poolUnlimited: {
  //     saleAmount: '100,000,000 BLINK',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.blink,
  //   releaseBlockNumber: 3279767,
  //   campaignId: '511010000',
  //   articleUrl: 'https://medium.com/pancakeswap/1-000-000-ifo-blink-joins-pancakeswap-15841800bdd8',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
]

export default madbox
