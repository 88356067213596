/* eslint-disable no-debugger */
import BigNumber from 'bignumber.js'
import { BIG_ONE, BIG_ZERO } from 'utils/bigNumber'
import { filterFarmsByQuoteToken } from 'utils/farmsPriceHelpers'
import { SerializedFarm } from 'state/types'
import tokens from 'config/constants/tokens'
import { TokenPriceFromAPI } from 'state/pools/helpers'

const getFarmFromTokenSymbol = (
  farms: SerializedFarm[],
  tokenSymbol: string,
  isV2: boolean,
  preferredQuoteTokens?: string[],
): SerializedFarm => {
  const farmsWithTokenSymbol = farms.filter((farm) => farm.token.symbol === tokenSymbol && farm.isV2 === isV2)
  const filteredFarm = filterFarmsByQuoteToken(farmsWithTokenSymbol, preferredQuoteTokens)
  return filteredFarm
}

const getFarmBaseTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  croPriceUsdc: BigNumber,
): BigNumber => {
  const hasTokenPriceVsQuote = Boolean(farm.tokenPriceVsQuote)

  if (farm.quoteToken.symbol === tokens.usdc.symbol) {
    return hasTokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (farm.quoteToken.symbol === tokens.wcro.symbol) {
    return hasTokenPriceVsQuote ? croPriceUsdc.times(farm.tokenPriceVsQuote) : BIG_ZERO
  }

  // We can only calculate profits without a quoteTokenFarm for BUSD/CRO farms
  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  // Possible alternative farm quoteTokens:
  // UST (i.e. MIR-UST), pBTC (i.e. PNT-pBTC), BTCB (i.e. bBADGER-BTCB), ETH (i.e. SUSHI-ETH)
  // If the farm's quote token isn't BUSD or WCRO, we then use the quote token, of the original farm's quote token
  // i.e. for farm PNT - pBTC we use the pBTC farm's quote token - CRO, (pBTC - CRO)
  // from the CRO - pBTC price, we can calculate the PNT - BUSD price
  if (quoteTokenFarm.quoteToken.symbol === tokens.wcro.symbol) {
    const quoteTokenInUsdc = croPriceUsdc.times(quoteTokenFarm.tokenPriceVsQuote)
    return hasTokenPriceVsQuote && quoteTokenInUsdc
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInUsdc)
      : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === tokens.usdc.symbol) {
    const quoteTokenInUsdc = quoteTokenFarm.tokenPriceVsQuote
    return hasTokenPriceVsQuote && quoteTokenInUsdc
      ? new BigNumber(farm.tokenPriceVsQuote).times(quoteTokenInUsdc)
      : BIG_ZERO
  }

  // Catch in case token does not have immediate or once-removed BUSD/WCRO quoteToken
  return BIG_ZERO
}

const getFarmQuoteTokenPrice = (
  farm: SerializedFarm,
  quoteTokenFarm: SerializedFarm,
  croPriceUsdc: BigNumber,
  mmfPriceUsdc: BigNumber,
  svnPriceUsdc: BigNumber,
): BigNumber => {
  if (farm.quoteToken.symbol === 'USDC') {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'USDT') {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'DAI') {
    return BIG_ONE
  }

  if (farm.quoteToken.symbol === 'WCRO') {
    return croPriceUsdc
  }

  if (farm.quoteToken.symbol === 'MMF') {
    return mmfPriceUsdc
  }

  if (farm.quoteToken.symbol === 'SVN') {
    return svnPriceUsdc
  }

  if (!quoteTokenFarm) {
    return BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'WCRO') {
    return quoteTokenFarm.tokenPriceVsQuote ? croPriceUsdc.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'MMF') {
    return quoteTokenFarm.tokenPriceVsQuote ? mmfPriceUsdc.times(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'USDC') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'DAI') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  if (quoteTokenFarm.quoteToken.symbol === 'USDT') {
    return quoteTokenFarm.tokenPriceVsQuote ? new BigNumber(quoteTokenFarm.tokenPriceVsQuote) : BIG_ZERO
  }

  return BIG_ZERO
}

const fetchFarmsPrices = async (farms: SerializedFarm[]) => {
  const prices = await TokenPriceFromAPI(0, 0, 0)
  const croUsdcFarm = farms.find((farm) => farm.pid === 4) // FIXME pid for CRO/USDC
  const mmfusdcFarm = farms.find((farm) => farm.pid === 7) // FIXME

  // const croUsdcFarm = farms.find(
  //   (farm) => farm.token.symbol === tokens.usdc.symbol && farm.quoteToken.symbol === tokens.wcro.symbol,
  // ) // FIXME pid for CRO/USDC

  const croPriceUsdc = new BigNumber(croUsdcFarm.tokenPriceVsQuote)
  const mmfPriceUsdc = new BigNumber(mmfusdcFarm.tokenPriceVsQuote)
  const svnPriceUsdc = new BigNumber(prices['0x654bac3ec77d6db497892478f854cf6e8245dca9'])

  const farmsWithPrices = farms.map((farm) => {
    const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteToken.symbol, farm.isV2)
    const tokenPriceBusd = getFarmBaseTokenPrice(farm, quoteTokenFarm, croPriceUsdc)
    const quoteTokenPriceBusd = getFarmQuoteTokenPrice(farm, quoteTokenFarm, croPriceUsdc, mmfPriceUsdc, svnPriceUsdc)

    if (farm.lpSymbol === 'MAD-SVN LP') {
      return {
        ...farm,
        tokenPriceBusd: prices['0x212331e1435a8df230715db4c02b2a3a0abf8c61'],
        quoteTokenPriceBusd: prices['0x654bac3ec77d6db497892478f854cf6e8245dca9'],
      }
    }

    if (farm.lpSymbol === 'DONUT-SVN LP') {
      return {
        ...farm,
        tokenPriceBusd: prices['0x2258459c937d0b4cb58289718c900b43c9c4813a'],
        quoteTokenPriceBusd: prices['0x654bac3ec77d6db497892478f854cf6e8245dca9'],
      }
    }

    return {
      ...farm,
      tokenPriceBusd: tokenPriceBusd.toJSON(),
      quoteTokenPriceBusd: quoteTokenPriceBusd.toJSON(),
    }
  })

  return farmsWithPrices
}

export default fetchFarmsPrices
