import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import farmsConfig from 'config/constants/farms'
import isArchivedPid from 'utils/farmHelpers'
import priceHelperLpsConfig from 'config/constants/priceHelperLps'
import { governanceExtendLps } from 'config/constants/governance'
import { getNullAddress } from 'utils/addressHelpers'
import fetchFarms from './fetchFarms'
import fetchFarmsPrices from './fetchFarmsPrices'
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
  fetchFarmUserNftSlots,
  fetchFarmUserNftTokenIds,
  fetchFarmUserBoost,
} from './fetchFarmUser'
import { SerializedFarmsState, SerializedFarm } from '../types'

const nullAdd = getNullAddress()

const noAccountFarmConfig = farmsConfig.map((farm) => ({
  ...farm,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0',
    farmNftSlots: [nullAdd, nullAdd, nullAdd],
    farmNftTokenIds: [
      { series: '0', id: '0' },
      { series: '0', id: '0' },
      { series: '0', id: '0' },
    ],
    farmBoostRate: '0',
  },
}))

const noAccountGovernanceExtendConfig = governanceExtendLps.map((farm) => ({
  ...farm,
  userData: {
    allowance: '0',
    tokenBalance: '0',
    stakedBalance: '0',
    earnings: '0',
    farmNftSlots: [nullAdd, nullAdd, nullAdd],
    farmNftTokenIds: [
      { series: '0', id: '0' },
      { series: '0', id: '0' },
      { series: '0', id: '0' },
    ],
    farmBoostRate: '0',
  },
}))

const initialState: SerializedFarmsState = {
  data: noAccountFarmConfig,
  governanceExtendLps: noAccountGovernanceExtendConfig,
  loadArchivedFarmsData: false,
  userDataLoaded: false,
}

export const nonArchivedFarms = farmsConfig.filter(({ pid }) => !isArchivedPid(pid))

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<{
  farmsWithoutHelperLps: SerializedFarm[]
  farmGovernanceExtendLps: SerializedFarm[]
}>(
  'farms/fetchFarmsPublicDataAsync',
  async () => {
    // Add price helper farms
    const farmsWithPriceHelpers = farmsConfig.concat(priceHelperLpsConfig).concat(governanceExtendLps)

    const farms = await fetchFarms(farmsWithPriceHelpers)
    const farmsWithPrices = await fetchFarmsPrices(farms)

    // Filter out price helper LP config farms
    const farmsWithoutHelperLps = farmsWithPrices.filter((farm: SerializedFarm) => {
      return farm.pid || farm.pid === 0
    })
    const farmGovernanceExtendLps = farmsWithPrices.filter((farm: SerializedFarm) => {
      return farm.pid === null
    })

    return {
      farmsWithoutHelperLps,
      farmGovernanceExtendLps
    }
  },
)

interface FarmUserDataResponse {
  pid: number
  lpSymbol: string
  isV2: boolean
  allowance: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
  farmNftSlots: string[]
  farmNftTokenIds: FarmNftTokenId[]
  farmBoostRate: string
}

interface FarmNftTokenId {
  series: string
  id: string
}

export const fetchFarmUserDataAsync = createAsyncThunk<
  FarmUserDataResponse[],
  { account: string; pids: number[]; isV2: boolean }
>('farms/fetchFarmUserDataAsync', async ({ account, pids, isV2 }) => {
  const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid) && farmConfig.isV2 === isV2)
  const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch)
  const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch)
  const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch)
  const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch)
  const userFarmNftSlots = await fetchFarmUserNftSlots(account, farmsToFetch)
  const userFarmNftTokenIds = await fetchFarmUserNftTokenIds(account, farmsToFetch)
  const userFarmBoost = await fetchFarmUserBoost(account, farmsToFetch)

  return userFarmAllowances.map((farmAllowance, index) => {
    return {
      pid: farmsToFetch[index].pid,
      lpSymbol: farmsToFetch[index].lpSymbol,
      isV2: farmsToFetch[index].isV2,
      allowance: userFarmAllowances[index],
      tokenBalance: userFarmTokenBalances[index],
      stakedBalance: userStakedBalances[index],
      earnings: userFarmEarnings[index],
      farmNftSlots: userFarmNftSlots[index] ?? [nullAdd, nullAdd, nullAdd],
      farmNftTokenIds: userFarmNftTokenIds[index] ?? [
        { series: '0', id: '0' },
        { series: '0', id: '0' },
        { series: '0', id: '0' },
      ],
      farmBoostRate: userFarmBoost[index] ?? '0',
    }
  })
})

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setLoadArchivedFarmsData: (state, action) => {
      const loadArchivedFarmsData = action.payload
      state.loadArchivedFarmsData = loadArchivedFarmsData
    },
  },
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
      state.data = state.data.map((farm) => {
        const liveFarmData = action.payload.farmsWithoutHelperLps.find(
          (farmData) =>
            `${farmData.pid}+${farmData.lpSymbol}+${farmData.isV2 ? 'V2' : 'V1'}` ===
            `${farm.pid}+${farm.lpSymbol}+${farm.isV2 ? 'V2' : 'V1'}`,
        )
        return { ...farm, ...liveFarmData }
      })
      state.governanceExtendLps = state.governanceExtendLps.map((farm) => {
        const liveFarmData = action.payload.farmGovernanceExtendLps.find(
          (farmData) =>
            `${farmData.pid}+${farmData.lpSymbol}+${farmData.isV2 ? 'V2' : 'V1'}` ===
            `${farm.pid}+${farm.lpSymbol}+${farm.isV2 ? 'V2' : 'V1'}`,
        )
        return { ...farm, ...liveFarmData }
      })
    })

    // Update farms with user data
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid, lpSymbol, isV2 } = userDataEl
        const index = state.data.findIndex(
          (farm) =>
            `${pid}+${lpSymbol}+${isV2 ? 'V2' : 'V1'}` === `${farm.pid}+${farm.lpSymbol}+${farm.isV2 ? 'V2' : 'V1'}`,
        )
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })
  },
})

// Actions
export const { setLoadArchivedFarmsData } = farmsSlice.actions

export default farmsSlice.reducer
