import BigNumber from 'bignumber.js'
import masterchefABI from 'config/abi/masterchef.json'
import erc20 from 'config/abi/erc20.json'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'
import { SerializedFarm, SerializedBigNumber } from '../types'

type PublicFarmData = {
  tokenAmountTotal: SerializedBigNumber
  lpTotalInQuoteToken: SerializedBigNumber
  quoteTokenAmountMc: SerializedBigNumber
  lpTotalSupply: SerializedBigNumber
  tokenPriceVsQuote: SerializedBigNumber
  poolWeight: SerializedBigNumber
  multiplier: string
  lpTokenBalanceMC: SerializedBigNumber
}

const fetch3MMData = async (farm: SerializedFarm): Promise<PublicFarmData> => {
  const { pid, lpAddresses, token, quoteToken, basePool } = farm

  const lpAddress = getAddress(lpAddresses)
  const calls = [
    // Balance of token in the LP contract
    {
      address: token.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      address: quoteToken.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of LP tokens aka 3MM token in the master chef contract
    {
      address: token.address,
      name: 'balanceOf',
      params: [getMasterChefAddress(farm.isV2)],
    },
    // Total supply of LP aka 3MM token tokens
    {
      address: token.address,
      name: 'totalSupply',
    },
    // Token decimals
    {
      address: token.address,
      name: 'decimals',
    },
    // Quote token decimals
    {
      address: quoteToken.address,
      name: 'decimals',
    },
    // Underlying price
    {
      address: basePool,
      name: 'get_virtual_price',
    },
  ]

  const [
    tokenBalanceLP,
    quoteTokenBalanceLP,
    lpTokenBalanceMC,
    lpTotalSupply,
    tokenDecimals,
    quoteTokenDecimals,
    virtualPrice,
  ] = await multicall(erc20, calls)

  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
  // const lpTokenRatio = new BigNumber(1000000).div(new BigNumber(1000)) // FIXME debug hard code for farms

  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
  const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))
  // const tokenAmountTotal = new BigNumber(token.symbol === 'WORKBENCH' ? new BigNumber(10000000000000000000000000) : tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals)) // FIXME debug hard code for farms
  // const quoteTokenAmountTotal = new BigNumber(quoteToken.symbol === 'WCRO' ? new BigNumber(1000000000000000000) : quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals)) // FIXME debug hard code for farms
  // Amount of quoteToken in the LP that are staked in the MC
  const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

  // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = new BigNumber(lpTokenBalanceMC).div(BIG_TEN.pow(18))

  // Only make masterchef calls if farm has pid
  const [info, totalAllocPoint] =
    pid || pid === 0
      ? await multicall(masterchefABI, [
          {
            address: getMasterChefAddress(farm.isV2),
            name: 'poolInfo',
            params: [pid],
          },
          {
            address: getMasterChefAddress(farm.isV2),
            name: 'totalAllocPoint',
          },
        ])
      : [null, null]

  const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ZERO
  const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint)) : BIG_ZERO

  return {
    tokenAmountTotal: new BigNumber(lpTokenBalanceMC).div(BIG_TEN.pow(18)).toJSON(),
    lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
    tokenPriceVsQuote: new BigNumber(virtualPrice).div(BIG_TEN.pow(18)).toJSON(),
    poolWeight: poolWeight.toJSON(),
    multiplier: `${allocPoint.div(100).toString()}X`,
    lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).toJSON(),
    quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
  }
}

const fetchFarm = async (farm: SerializedFarm): Promise<PublicFarmData> => {
  const { pid, lpAddresses, token, quoteToken, isStableSwap } = farm

  if (!isStableSwap) {
    const lpAddress = getAddress(lpAddresses)
    const calls = [
      // Balance of token in the LP contract
      {
        address: token.address,
        name: 'balanceOf',
        params: [lpAddress],
      },
      // Balance of quote token on LP contract
      {
        address: quoteToken.address,
        name: 'balanceOf',
        params: [lpAddress],
      },
      // Balance of LP tokens in the master chef contract
      {
        address: lpAddress,
        name: 'balanceOf',
        params: [getMasterChefAddress(farm.isV2)],
      },
      // Total supply of LP tokens
      {
        address: lpAddress,
        name: 'totalSupply',
      },
      // Token decimals
      {
        address: token.address,
        name: 'decimals',
      },
      // Quote token decimals
      {
        address: quoteToken.address,
        name: 'decimals',
      },
    ]

    const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply, tokenDecimals, quoteTokenDecimals] =
      await multicall(erc20, calls)

    // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
    const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
    // const lpTokenRatio = new BigNumber(1000000).div(new BigNumber(1000)) // FIXME debug hard code for farms

    // Raw amount of token in the LP, including those not staked
    const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
    const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))
    // const tokenAmountTotal = new BigNumber(token.symbol === 'WORKBENCH' ? new BigNumber(10000000000000000000000000) : tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals)) // FIXME debug hard code for farms
    // const quoteTokenAmountTotal = new BigNumber(quoteToken.symbol === 'WCRO' ? new BigNumber(1000000000000000000) : quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals)) // FIXME debug hard code for farms
    // Amount of quoteToken in the LP that are staked in the MC
    const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

    // Total staked in LP, in quote token value
    const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))

    // Only make masterchef calls if farm has pid
    let info
    let totalAllocPoint
    try {
      [info, totalAllocPoint] =
        pid || pid === 0
          ? await multicall(masterchefABI, [
            {
              address: getMasterChefAddress(farm.isV2),
              name: 'poolInfo',
              params: [pid],
            },
            {
              address: getMasterChefAddress(farm.isV2),
              name: 'totalAllocPoint',
            },
          ])
          : [null, null]
    } catch (e) {
      return {
        tokenAmountTotal: tokenAmountTotal.toJSON(),
        lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
        poolWeight: BIG_ZERO.toJSON(),
        multiplier: `${BIG_ZERO.div(100).toString()}X`,
        lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).toJSON(),
        quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
      }
    }

    const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ZERO
    const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint)) : BIG_ZERO

    return {
      tokenAmountTotal: tokenAmountTotal.toJSON(),
      lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
      lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
      tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
      poolWeight: poolWeight.toJSON(),
      multiplier: `${allocPoint.div(100).toString()}X`,
      lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).toJSON(),
      quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
    }
  }

  return fetch3MMData(farm)
}

export default fetchFarm
