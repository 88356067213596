import { ChainId } from '@crocswap/sdk'
import { BoostPoolInfo } from './types'

const mainnetBoostPools: BoostPoolInfo[] = [
  {
    pid: 0,
    multiplier: 100,
    lockPeriod: 604800,
    imgSrc: '/images/veMMF/vemmf-lock-one-week.svg',
  },
  {
    pid: 1,
    multiplier: 600,
    lockPeriod: 15552000,
    imgSrc: '/images/veMMF/vemmf-lock-half-year.svg',
  },
  {
    pid: 2,
    multiplier: 1800,
    lockPeriod: 31536000,
    imgSrc: '/images/veMMF/vemmf-lock-one-year.svg',
  },
  {
    pid: 3,
    multiplier: 10800,
    lockPeriod: 126230400,
    imgSrc: '/images/veMMF/vemmf-lock-four-years.svg',
  },
]

const testnetBoostPools: BoostPoolInfo[] = [
  {
    pid: 0,
    multiplier: 100,
    lockPeriod: 300,
    imgSrc: '/images/veMMF/vemmf-lock-one-week.svg',
  },
  {
    pid: 1,
    multiplier: 600,
    lockPeriod: 3600,
    imgSrc: '/images/veMMF/vemmf-lock-half-year.svg',
  },
  {
    pid: 2,
    multiplier: 1800,
    lockPeriod: 86400,
    imgSrc: '/images/veMMF/vemmf-lock-one-year.svg',
  },
  {
    pid: 3,
    multiplier: 10800,
    lockPeriod: 129600,
    imgSrc: '/images/veMMF/vemmf-lock-four-years.svg',
  },
]

const boostPools: BoostPoolInfo[] =
  process.env.REACT_APP_CHAIN_ID === ChainId.MAINNET.toString() ? mainnetBoostPools : testnetBoostPools

export default boostPools
