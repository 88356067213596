import { useContext, useMemo, useState } from 'react'
import { ThemeContext as StyledThemeContext } from 'styled-components'
import { useThemeManager } from 'state/user/hooks'

export const mediaWidth = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}


const useTheme = () => {
  const [isDark, toggleTheme] = useThemeManager()
  const theme = useContext(StyledThemeContext)
  // const [newTheme, setNewTheme] = useState({})

  const newTheme = useMemo(() => {
    return {...theme, mediaWidth}
  }, [theme])

  return { isDark, theme: newTheme, toggleTheme }
}

export default useTheme
