import { ChainId } from '@crocswap/sdk'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

// CRO-WETH
// CRO-WBTC
// CRO-USDC
// CRO-USDT
// USDC-USDT

// CROC-CRO

// WETH - 0xe44Fd7fCb2b1581822D0c862B68222998a0c299a
// WBTC - 0x062E66477Faf219F25D27dCED647BF57C3107d52
// USDC - 0xc21223249CA28397B4B6541dfFaEcC539BfF0c59
// USDT - 0x66e428c3f67a68878562e79A0234c1F83c208770
// WCRO - 0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23

// CROC-CRO 40x
// CRO-ETH 10x
// CRO-WBTC 10x
// CRO-USDC 10x
// CRO-USDT 10x
// USDC-USDT 5x

const serializedTokens = serializeTokens()

const mainnetFarms: SerializedFarmConfig[] = [
  //   /**
  //    * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
  //    */
  {
    pid: 0,
    lpSymbol: 'MMF',
    lpAddresses: {
      25: '0xbA452A1c0875D33a440259B1ea4DcA8f5d86D9Ae',
      338: '0xbA452A1c0875D33a440259B1ea4DcA8f5d86D9Ae',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 1,
    lpSymbol: 'MMF-CRO LP',
    lpAddresses: {
      25: '0xbA452A1c0875D33a440259B1ea4DcA8f5d86D9Ae',
      338: '0xbA452A1c0875D33a440259B1ea4DcA8f5d86D9Ae',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 7,
    lpSymbol: 'MMF-USDC LP',
    lpAddresses: {
      25: '0x722f19bd9A1E5bA97b3020c6028c279d27E4293C',
      338: '0x722f19bd9A1E5bA97b3020c6028c279d27E4293C',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.usdc,
    isV2: false,
  },
  {
    pid: 9,
    lpSymbol: 'MMF-USDT LP',
    lpAddresses: {
      25: '0x5801d37e04ab1f266c35a277e06c9d3afa1c9ca2',
      338: '0x5801d37e04ab1f266c35a277e06c9d3afa1c9ca2',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.usdt,
    isV2: false,
  },
  {
    pid: 10,
    lpSymbol: 'MMO-CRO LP',
    lpAddresses: {
      25: '0xf0b5074DBf73c96d766C9A48726CEe7a6074D436',
      338: '0xf0b5074DBf73c96d766C9A48726CEe7a6074D436',
    },
    token: serializedTokens.mmo,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 11,
    lpSymbol: 'DAI-USDC LP',
    lpAddresses: {
      25: '0x787A47b0596fa8F7D6666F3C59696b3c57bB612b',
      338: '0x787A47b0596fa8F7D6666F3C59696b3c57bB612b',
    },
    token: serializedTokens.dai,
    quoteToken: serializedTokens.usdc,
    isV2: false,
  },
  {
    pid: 12,
    lpSymbol: 'LIQ-CRO LP',
    lpAddresses: {
      25: '0x081FbAE367269725af7a21479eddA39f62f4BAda',
      338: '0x081FbAE367269725af7a21479eddA39f62f4BAda',
    },
    token: serializedTokens.liq,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 13,
    lpSymbol: 'MIMAS-CRO LP',
    lpAddresses: {
      25: '0xf56fdfeef0ba3de23dab13a85602bd7bf135e80f',
      338: '0xf56fdfeef0ba3de23dab13a85602bd7bf135e80f',
    },
    token: serializedTokens.mimas,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 14,
    lpSymbol: 'BISON-CRO LP',
    lpAddresses: {
      25: '0xd3FD1eA9f86c6C6Bbdc6536a2247392D764543fD',
      338: '0xd3FD1eA9f86c6C6Bbdc6536a2247392D764543fD',
    },
    token: serializedTokens.bison,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 15,
    lpSymbol: 'CROISSANT-MMF LP',
    lpAddresses: {
      25: '0xde991150329dbe53389db41db459cae3ff220bac',
      338: '0xde991150329dbe53389db41db459cae3ff220bac',
    },
    token: serializedTokens.croissant,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 16,
    lpSymbol: 'CRK-WCRO LP',
    lpAddresses: {
      25: '0xc2f62bd1416845f606c5e48181743f7128a30ee3',
      338: '0xc2f62bd1416845f606c5e48181743f7128a30ee3',
    },
    token: serializedTokens.crk,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 17,
    lpSymbol: 'CRP-MMF LP',
    lpAddresses: {
      25: '0x1338D3C3Cc56f71B45f95F9988e762e4a1EF228D',
      338: '0x1338D3C3Cc56f71B45f95F9988e762e4a1EF228D',
    },
    token: serializedTokens.crp,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 18,
    lpSymbol: 'DNA-USDC LP',
    lpAddresses: {
      25: '0x853067186eeB57241d8D460bD8c3aA92CBF6f60e',
      338: '0x853067186eeB57241d8D460bD8c3aA92CBF6f60e',
    },
    token: serializedTokens.dna,
    quoteToken: serializedTokens.usdc,
    isV2: false,
  },
  {
    pid: 19,
    lpSymbol: 'MOON-wCRO LP',
    lpAddresses: {
      25: '0xAeFd1c8B1acC0ECCba26d5c6c712dDf4741E24C7',
      338: '0xAeFd1c8B1acC0ECCba26d5c6c712dDf4741E24C7',
    },
    token: serializedTokens.moon,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 20,
    lpSymbol: 'ANN-CRO LP',
    lpAddresses: {
      25: '0x3DD1617e3E8ACf086efb41f2E3b3732A381DB140',
      338: '0x3DD1617e3E8ACf086efb41f2E3b3732A381DB140',
    },
    token: serializedTokens.ann,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 21,
    lpSymbol: 'SPHERE-MMF LP',
    lpAddresses: {
      25: '0x8ec4F97DE93B4B7BeA29EE5a1E452d1481D62BfC',
      338: '0x8ec4F97DE93B4B7BeA29EE5a1E452d1481D62BfC',
    },
    token: serializedTokens.sphere,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 22,
    lpSymbol: 'SINGLE-USDC LP',
    lpAddresses: {
      25: '0x2d485E96e02dcF502B1F8C367523B29d4139d596',
      338: '0x2d485E96e02dcF502B1F8C367523B29d4139d596',
    },
    token: serializedTokens.single,
    quoteToken: serializedTokens.usdc,
    isV2: false,
  },
  {
    pid: 23,
    lpSymbol: 'BETIFY-MMF LP',
    lpAddresses: {
      25: '0xe2c5275d86d2fb860f19a2cbbed9967d39aa73e8',
      338: '0xe2c5275d86d2fb860f19a2cbbed9967d39aa73e8',
    },
    token: serializedTokens.betify,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 24,
    lpSymbol: 'CGS-WCRO LP',
    lpAddresses: {
      25: '0x0DD34d4Ff37D045074b6A077A289eD3163372D47',
      338: '0x0DD34d4Ff37D045074b6A077A289eD3163372D47',
    },
    token: serializedTokens.cgs,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 25,
    lpSymbol: 'AGO-MMF LP',
    lpAddresses: {
      25: '0x90f27486424e0cc1d98e0144576637673570c903',
      338: '0x90f27486424e0cc1d98e0144576637673570c903',
    },
    token: serializedTokens.ago,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 26,
    lpSymbol: 'GRVE-MMF LP',
    lpAddresses: {
      25: '0x16B7F0Bc8332EDBa5a1B91Ac867c3E5EfD3827e6',
      338: '0x16B7F0Bc8332EDBa5a1B91Ac867c3E5EfD3827e6',
    },
    token: serializedTokens.grve,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 27,
    lpSymbol: 'METF-MMF LP',
    lpAddresses: {
      25: '0xd7385f46FFb877d8c8Fe78E5f5a7c6b2F18C05A7',
      338: '0xd7385f46FFb877d8c8Fe78E5f5a7c6b2F18C05A7',
    },
    token: serializedTokens.metf,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 28,
    lpSymbol: 'GOAL-MMF LP',
    lpAddresses: {
      25: '0xd36c36dE5D1F328BBCb9d74c55EcDa5A2Fb94e23',
      338: '0xd36c36dE5D1F328BBCb9d74c55EcDa5A2Fb94e23',
    },
    token: serializedTokens.goal,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 29,
    lpSymbol: 'SRV-MMF LP',
    lpAddresses: {
      25: '0x2C24fFfD72485d0cB329b95B569e0d7C4061FE82',
      338: '0x2C24fFfD72485d0cB329b95B569e0d7C4061FE82',
    },
    token: serializedTokens.srv,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 30,
    lpSymbol: 'MAD-MMF LP',
    lpAddresses: {
      25: '0x7444491828253452B7a933A5Aa0A74B55A86cdb0',
      338: '0x7444491828253452B7a933A5Aa0A74B55A86cdb0',
    },
    token: serializedTokens.mad,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 8,
    lpSymbol: 'ETH-WBTC LP',
    lpAddresses: {
      25: '0x0101112C7aDdb2E8197922e9cFa17cbAA935ECCc',
      338: '0x0101112C7aDdb2E8197922e9cFa17cbAA935ECCc',
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.wbtc,
    isV2: false,
  },
  {
    pid: 2,
    lpSymbol: 'ETH-CRO LP',
    lpAddresses: {
      25: '0x019d9479606FBDd4aCB16488e0aAE49E4684322b',
      338: '0x019d9479606FBDd4aCB16488e0aAE49E4684322b',
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 3,
    lpSymbol: 'WBTC-CRO LP',
    lpAddresses: {
      25: '0x5383202D48C24aAA19873366168f2Ed558a00ff0',
      338: '0x5383202D48C24aAA19873366168f2Ed558a00ff0',
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 4,
    lpSymbol: 'CRO-USDC LP',
    lpAddresses: {
      25: '0xa68466208F1A3Eb21650320D2520ee8eBA5ba623',
      338: '0xa68466208F1A3Eb21650320D2520ee8eBA5ba623',
    },
    token: serializedTokens.wcro,
    quoteToken: serializedTokens.usdc,
    isV2: false,
  },
  {
    pid: 5,
    lpSymbol: 'CRO-USDT LP',
    lpAddresses: {
      25: '0xEB28c926A7Afc75fcC8d6671Acd4c4A298b38419',
      338: '0xEB28c926A7Afc75fcC8d6671Acd4c4A298b38419',
    },
    token: serializedTokens.wcro,
    quoteToken: serializedTokens.usdt,
    isV2: false,
  },
  {
    pid: 6,
    lpSymbol: 'USDC-USDT LP',
    lpAddresses: {
      25: '0x6F186E4BEd830D13DcE638e40bA27Fd6d91BAd0B',
      338: '0x6F186E4BEd830D13DcE638e40bA27Fd6d91BAd0B',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.usdc,
    isV2: false,
  },
  {
    pid: 31,
    lpSymbol: '3MM LP',
    isStableSwap: true,
    numTokens: 3,
    basePool: '0x61bB2F4a4763114268a47fB990e633Cb40f045F8',
    lpAddresses: {
      25: '0x74759c8BCb6787ef25eD2ff432FE33ED57CcCB0D',
      338: '0x74759c8BCb6787ef25eD2ff432FE33ED57CcCB0D', // LP -> base pool address
    },
    token: serializedTokens.mm3, // 3MMLP token address
    quoteToken: serializedTokens.usdc,
    isV2: false,
  },
  {
    pid: 32,
    lpSymbol: 'MUSD-3MM LP',
    isStableSwap: true,
    numTokens: 2,
    basePool: '0xdB04E53eC3FAB887Be2F55C3fD79bC57855bC827',
    lpAddresses: {
      25: '0xdB04E53eC3FAB887Be2F55C3fD79bC57855bC827',
      338: '0xdB04E53eC3FAB887Be2F55C3fD79bC57855bC827', // LP -> base pool address
    },
    token: serializedTokens.musdmm3, // 3MMLP token address
    quoteToken: serializedTokens.mm3,
    isV2: false,
  },
  {
    pid: 33,
    lpSymbol: 'MUSD-USDC LP',
    lpAddresses: {
      25: '0x0B083d50417FEC1390C2C07Eba85f31D5EeFC350',
      338: '0x0B083d50417FEC1390C2C07Eba85f31D5EeFC350', // LP -> base pool address
    },
    token: serializedTokens.musd,
    quoteToken: serializedTokens.usdc,
    isV2: false,
  },
  {
    pid: 34,
    lpSymbol: 'MMF-MUSD LP',
    lpAddresses: {
      25: '0xeF2dC4849bDCC120acB7274cd5A557B5145DA149',
      338: '0xeF2dC4849bDCC120acB7274cd5A557B5145DA149', // LP -> base pool address
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.musd,
    isV2: false,
  },
  {
    pid: 35,
    lpSymbol: 'CRO-MUSD LP',
    lpAddresses: {
      25: '0x28F9Eb94c0E954ceE2E0407B084c760BaBf9F39c',
      338: '0x28F9Eb94c0E954ceE2E0407B084c760BaBf9F39c', // LP -> base pool address
    },
    token: serializedTokens.wcro,
    quoteToken: serializedTokens.musd,
    isV2: false,
  },
  {
    pid: 36,
    lpSymbol: 'ANN-MMF LP',
    lpAddresses: {
      25: '0xD41B1d8edEa36795f9BA4802e5A195925E720589',
      338: '0xD41B1d8edEa36795f9BA4802e5A195925E720589', // LP -> base pool address
    },
    token: serializedTokens.ann,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 37,
    lpSymbol: 'MAD-SVN LP',
    lpAddresses: {
      25: '0x4673749a3cb3b3e787b3e88ac36e356dddbe99b9',
      338: '0x4673749a3cb3b3e787b3e88ac36e356dddbe99b9', // LP -> base pool address
    },
    token: serializedTokens.mad,
    quoteToken: serializedTokens.svn,
    isV2: false,
  },
  {
    pid: 38,
    lpSymbol: 'DONUT-SVN LP',
    lpAddresses: {
      25: '0xCA5aa86E4e40a353020EcFAc4814A8F61FBB00B0',
      338: '0xCA5aa86E4e40a353020EcFAc4814A8F61FBB00B0', // LP -> base pool address
    },
    token: serializedTokens.donut,
    quoteToken: serializedTokens.svn,
    isV2: false,
  },
  {
    pid: 39,
    lpSymbol: 'MNG-MMF LP',
    lpAddresses: {
      25: '0xa7bE5cB8Be6484c06aBb8942fe682797bb9A76Ec',
      338: '0xa7bE5cB8Be6484c06aBb8942fe682797bb9A76Ec', // LP -> base pool address
    },
    token: serializedTokens.mng,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 40,
    lpSymbol: 'bCRO-CRO LP',
    lpAddresses: {
      25: '0xB3b66bE228b930C2F319621955CA507aDE3453dd',
      338: '0xB3b66bE228b930C2F319621955CA507aDE3453dd', // LP -> base pool address
    },
    token: serializedTokens.bcro,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 41,
    lpSymbol: 'BTC-USDC LP',
    lpAddresses: {
      25: '0x120A47f53f28c80e55F4b993189838B86D79EA3a',
      338: '0x120A47f53f28c80e55F4b993189838B86D79EA3a', // LP -> base pool address
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.usdc,
    isV2: false,
  },
  {
    pid: 42,
    lpSymbol: 'bcroMM LP',
    isStableSwap: true,
    basePool: '0xbF369D9c0Ab3107F4823a39B2fD2Ca0Ff5310425',
    lpAddresses: {
      25: '0xB996cE5bd3551C3A95A39AFb7dfdDD552657e38e',
      338: '0xB996cE5bd3551C3A95A39AFb7dfdDD552657e38e', // LP -> base pool address
    },
    numTokens: 2,
    token: serializedTokens.mmbcro, // 3MMLP token address
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 44,
    lpSymbol: 'MINTME-MMF LP',
    lpAddresses: {
      25: '0xb3941CA26cD367D556E7D0A214F3fd2234c3bbB0',
      338: '0xb3941CA26cD367D556E7D0A214F3fd2234c3bbB0', // LP -> base pool address
    },
    token: serializedTokens.mintme,
    quoteToken: serializedTokens.mmf,
    isV2: false,
  },
  {
    pid: 45,
    lpSymbol: 'VERSA-USDC LP',
    lpAddresses: {
      25: '0xB6894f071da907301b4F0C4D87D5f2e42E22402d',
      338: '0xB6894f071da907301b4F0C4D87D5f2e42E22402d', // LP -> base pool address
    },
    token: serializedTokens.versa,
    quoteToken: serializedTokens.usdc,
    isV2: false,
  },
  // FARMS V2 //
  {
    pid: 0,
    lpSymbol: 'MMF',
    lpAddresses: {
      25: '0xbA452A1c0875D33a440259B1ea4DcA8f5d86D9Ae',
      338: '0xbA452A1c0875D33a440259B1ea4DcA8f5d86D9Ae',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.wcro,
    isV2: true,
  },
  {
    pid: 1,
    lpSymbol: 'MMF-CRO LP',
    lpAddresses: {
      25: '0xbA452A1c0875D33a440259B1ea4DcA8f5d86D9Ae',
      338: '0xbA452A1c0875D33a440259B1ea4DcA8f5d86D9Ae',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.wcro,
    isV2: true,
  },
  {
    pid: 2,
    lpSymbol: 'MMF-USDC LP',
    lpAddresses: {
      25: '0x722f19bd9A1E5bA97b3020c6028c279d27E4293C',
      338: '0x722f19bd9A1E5bA97b3020c6028c279d27E4293C',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.usdc,
    isV2: true,
  },
  {
    pid: 3,
    lpSymbol: 'MMF-MUSD LP',
    lpAddresses: {
      25: '0xeF2dC4849bDCC120acB7274cd5A557B5145DA149',
      338: '0xeF2dC4849bDCC120acB7274cd5A557B5145DA149', // LP -> base pool address
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.musd,
    isV2: true,
  },
  {
    pid: 4,
    lpSymbol: 'WBTC-CRO LP',
    lpAddresses: {
      25: '0x5383202D48C24aAA19873366168f2Ed558a00ff0',
      338: '0x5383202D48C24aAA19873366168f2Ed558a00ff0',
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.wcro,
    isV2: true,
  },
  {
    pid: 5,
    lpSymbol: 'CRO-USDC LP',
    lpAddresses: {
      25: '0xa68466208F1A3Eb21650320D2520ee8eBA5ba623',
      338: '0xa68466208F1A3Eb21650320D2520ee8eBA5ba623',
    },
    token: serializedTokens.wcro,
    quoteToken: serializedTokens.usdc,
    isV2: true,
  },
  {
    pid: 6,
    lpSymbol: 'CRO-USDT LP',
    lpAddresses: {
      25: '0xEB28c926A7Afc75fcC8d6671Acd4c4A298b38419',
      338: '0xEB28c926A7Afc75fcC8d6671Acd4c4A298b38419',
    },
    token: serializedTokens.wcro,
    quoteToken: serializedTokens.usdt,
    isV2: true,
  },
  {
    pid: 7,
    lpSymbol: 'USDC-USDT LP',
    lpAddresses: {
      25: '0x6F186E4BEd830D13DcE638e40bA27Fd6d91BAd0B',
      338: '0x6F186E4BEd830D13DcE638e40bA27Fd6d91BAd0B',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.usdc,
    isV2: true,
  },
  {
    pid: 8,
    lpSymbol: 'ETH-CRO LP',
    lpAddresses: {
      25: '0x019d9479606FBDd4aCB16488e0aAE49E4684322b',
      338: '0x019d9479606FBDd4aCB16488e0aAE49E4684322b',
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.wcro,
    isV2: true,
  },
  {
    pid: 9,
    lpSymbol: 'ETH-WBTC LP',
    lpAddresses: {
      25: '0x0101112C7aDdb2E8197922e9cFa17cbAA935ECCc',
      338: '0x0101112C7aDdb2E8197922e9cFa17cbAA935ECCc',
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.wbtc,
    isV2: true,
  },
  {
    pid: 10,
    lpSymbol: 'MMF-USDT LP',
    lpAddresses: {
      25: '0x5801d37e04ab1f266c35a277e06c9d3afa1c9ca2',
      338: '0x5801d37e04ab1f266c35a277e06c9d3afa1c9ca2',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.usdt,
    isV2: true,
  },
  {
    pid: 11,
    lpSymbol: 'MMO-CRO LP',
    lpAddresses: {
      25: '0xf0b5074DBf73c96d766C9A48726CEe7a6074D436',
      338: '0xf0b5074DBf73c96d766C9A48726CEe7a6074D436',
    },
    token: serializedTokens.mmo,
    quoteToken: serializedTokens.wcro,
    isV2: true,
  },
  {
    pid: 12,
    lpSymbol: 'DAI-USDC LP',
    lpAddresses: {
      25: '0x787A47b0596fa8F7D6666F3C59696b3c57bB612b',
      338: '0x787A47b0596fa8F7D6666F3C59696b3c57bB612b',
    },
    token: serializedTokens.dai,
    quoteToken: serializedTokens.usdc,
    isV2: true,
  },
  {
    pid: 13,
    lpSymbol: 'MUSD-3MM LP',
    isStableSwap: true,
    numTokens: 2,
    basePool: '0xdB04E53eC3FAB887Be2F55C3fD79bC57855bC827',
    lpAddresses: {
      25: '0xdB04E53eC3FAB887Be2F55C3fD79bC57855bC827',
      338: '0xdB04E53eC3FAB887Be2F55C3fD79bC57855bC827', // LP -> base pool address
    },
    token: serializedTokens.musdmm3, // 3MMLP token address
    quoteToken: serializedTokens.mm3,
    isV2: true,
  },
  {
    pid: 14,
    lpSymbol: 'MUSD-USDC LP',
    lpAddresses: {
      25: '0x0B083d50417FEC1390C2C07Eba85f31D5EeFC350',
      338: '0x0B083d50417FEC1390C2C07Eba85f31D5EeFC350', // LP -> base pool address
    },
    token: serializedTokens.musd,
    quoteToken: serializedTokens.usdc,
    isV2: true,
  },
  {
    pid: 15,
    lpSymbol: 'METF-MMF LP',
    lpAddresses: {
      25: '0xd7385f46FFb877d8c8Fe78E5f5a7c6b2F18C05A7',
      338: '0xd7385f46FFb877d8c8Fe78E5f5a7c6b2F18C05A7',
    },
    token: serializedTokens.metf,
    quoteToken: serializedTokens.mmf,
    isV2: true,
  },
  {
    pid: 16,
    lpSymbol: 'CRO-MUSD LP',
    lpAddresses: {
      25: '0x28F9Eb94c0E954ceE2E0407B084c760BaBf9F39c',
      338: '0x28F9Eb94c0E954ceE2E0407B084c760BaBf9F39c', // LP -> base pool address
    },
    token: serializedTokens.wcro,
    quoteToken: serializedTokens.musd,
    isV2: true,
  },
  {
    pid: 30,
    lpSymbol: 'MINTME-MMF LP',
    lpAddresses: {
      25: '0xb3941CA26cD367D556E7D0A214F3fd2234c3bbB0',
      338: '0xb3941CA26cD367D556E7D0A214F3fd2234c3bbB0', // LP -> base pool address
    },
    token: serializedTokens.mintme,
    quoteToken: serializedTokens.mmf,
    isV2: true,
  },
  {
    pid: 31,
    lpSymbol: 'CROISSANT-MMF LP',
    lpAddresses: {
      25: '0xDE991150329dbe53389dB41DB459cAe3fF220bac',
      338: '0xDE991150329dbe53389dB41DB459cAe3fF220bac', // LP -> base pool address
    },
    token: serializedTokens.croissant,
    quoteToken: serializedTokens.mmf,
    isV2: true,
  },
  {
    pid: 32,
    lpSymbol: 'SINGLE-USDC LP',
    lpAddresses: {
      25: '0x2d485E96e02dcF502B1F8C367523B29d4139d596',
      338: '0x2d485E96e02dcF502B1F8C367523B29d4139d596', // LP -> base pool address
    },
    token: serializedTokens.single,
    quoteToken: serializedTokens.usdc,
    isV2: true,
  },
  {
    pid: 33,
    lpSymbol: 'VERSA-USDC LP',
    lpAddresses: {
      25: '0xB6894f071da907301b4F0C4D87D5f2e42E22402d',
      338: '0xB6894f071da907301b4F0C4D87D5f2e42E22402d', // LP -> base pool address
    },
    token: serializedTokens.versa,
    quoteToken: serializedTokens.usdc,
    isV2: true,
  },
  {
    pid: 34,
    lpSymbol: 'MIMAS-CRO LP',
    lpAddresses: {
      25: '0xf56FDfeeF0Ba3de23DaB13a85602bd7BF135E80f',
      338: '0xf56FDfeeF0Ba3de23DaB13a85602bd7BF135E80f', // LP -> base pool address
    },
    token: serializedTokens.mimas,
    quoteToken: serializedTokens.wcro,
    isV2: true,
  },
  {
    pid: 35,
    lpSymbol: 'bCRO-CRO LP',
    lpAddresses: {
      25: '0xB3b66bE228b930C2F319621955CA507aDE3453dd',
      338: '0xB3b66bE228b930C2F319621955CA507aDE3453dd', // LP -> base pool address
    },
    token: serializedTokens.bcro,
    quoteToken: serializedTokens.wcro,
    isV2: true,
  },
  {
    pid: 36,
    lpSymbol: 'MAD-SVN LP',
    lpAddresses: {
      25: '0x4673749a3cb3B3E787b3e88Ac36e356DddBe99B9',
      338: '0x4673749a3cb3B3E787b3e88Ac36e356DddBe99B9', // LP -> base pool address
    },
    token: serializedTokens.mad,
    quoteToken: serializedTokens.svn,
    isV2: true,
  },
  {
    pid: 37,
    lpSymbol: 'DONUT-SVN LP',
    lpAddresses: {
      25: '0xCA5aa86E4e40a353020EcFAc4814A8F61FBB00B0',
      338: '0xCA5aa86E4e40a353020EcFAc4814A8F61FBB00B0', // LP -> base pool address
    },
    token: serializedTokens.donut,
    quoteToken: serializedTokens.svn,
    isV2: true,
  },
  {
    pid: 38,
    lpSymbol: 'MNG-MMF LP',
    lpAddresses: {
      25: '0xa7bE5cB8Be6484c06aBb8942fe682797bb9A76Ec',
      338: '0xa7bE5cB8Be6484c06aBb8942fe682797bb9A76Ec', // LP -> base pool address
    },
    token: serializedTokens.mng,
    quoteToken: serializedTokens.mmf,
    isV2: true,
  },
  {
    pid: 39,
    lpSymbol: 'ANN-MMF LP',
    lpAddresses: {
      25: '0xD41B1d8edEa36795f9BA4802e5A195925E720589',
      338: '0xD41B1d8edEa36795f9BA4802e5A195925E720589', // LP -> base pool address
    },
    token: serializedTokens.ann,
    quoteToken: serializedTokens.mmf,
    isV2: true,
  },
  {
    pid: 40,
    lpSymbol: 'ICY-CRO LP',
    lpAddresses: {
      25: '0x43DE574857F2a736D8db7386A687e4A09ca7471F',
      338: '0x43DE574857F2a736D8db7386A687e4A09ca7471F', // LP -> base pool address
    },
    token: serializedTokens.icy,
    quoteToken: serializedTokens.wcro,
    isV2: true,
  },
  {
    pid: 42,
    lpSymbol: 'ICY-bCRO LP',
    lpAddresses: {
      25: '0x02f958cb9774807Cc31290F653ee7b15575778cB',
      338: '0x02f958cb9774807Cc31290F653ee7b15575778cB', // LP -> base pool address
    },
    token: serializedTokens.icy,
    quoteToken: serializedTokens.bcro,
    isV2: true,
  },
]

const testnetFarms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'MMF',
    lpAddresses: {
      338: '0x904Bd5a5AAC0B9d88A0D47864724218986Ad4a3a',
      25: '',
    },
    token: serializedTokens.workbench,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 1,
    lpSymbol: 'CRO-ETH LP',
    lpAddresses: {
      338: '0xD37d267B2161035A9CF2023F9753CddbfC8B90F3',
      25: '',
    },
    token: serializedTokens.eth,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 2,
    lpSymbol: 'CRO-WBTC LP',
    lpAddresses: {
      338: '0xbDAA80e9EA59bFD3A8AE5705c66804F2e1D9D508',
      25: '',
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 3,
    lpSymbol: 'CRO-MMF LP',
    lpAddresses: {
      338: '0x3F3465b412B6017E20AF7ABFFAB67698de8cFa7a',
      25: '',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 4,
    lpSymbol: 'CRO-USDC LP',
    lpAddresses: {
      338: '0x5323D7CE6a12282c0448d255d8B5EDc9918E52Da',
      25: '',
    },
    token: serializedTokens.usdc,
    quoteToken: serializedTokens.wcro,
    isV2: false,
  },
  {
    pid: 5,
    lpSymbol: 'MMF-USDC LP',
    lpAddresses: {
      338: '0x9e3A0434ED428dFDe5ace779ead35e141DD95eFf',
      25: '',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.usdc,
    isV2: false,
  },
]

const farms: SerializedFarmConfig[] =
  process.env.REACT_APP_CHAIN_ID === ChainId.MAINNET.toString() ? mainnetFarms : testnetFarms

export default farms
