import { createSlice } from '@reduxjs/toolkit'
import { AppThunk, MmfXState } from 'state/types'
import fetchMmfBarAllowance, { fetchMmfBarBalance, fetchMmfBarTotalSupply } from './fetchMmfBarUser'

const initialState: MmfXState = {
  userDataLoaded: false,
  barData: {
    allowance: '0',
    stakedBalance: '0',
    totalSupply: '0',
    vaults: '0',
    mine: '0',
  },
}

export const fetchMmfBarPoolDataAsync = (): AppThunk => async (dispatch) => {
  const stakedBalance = await fetchMmfBarBalance()
  const totalSupply = await fetchMmfBarTotalSupply()

  const userData = {
    stakedBalance,
    totalSupply,
  }

  dispatch(updateMmfBarUserData(userData))
}

export const fetchMmfBarUserDataAsync =
  (account: string): AppThunk =>
  async (dispatch) => {
    const allowance = await fetchMmfBarAllowance(account)

    const userData = {
      allowance,
    }

    dispatch(updateMmfBarUserData(userData))
  }

export const veMmfSlice = createSlice({
  name: 'vemmf',
  initialState,
  reducers: {
    setMmfBarUserData: (state, action) => {
      state.barData = action.payload
      state.userDataLoaded = true
    },
    updateMmfBarUserData: (state, action) => {
      const value = action.payload
      state.barData = { ...state.barData, ...value }
      state.userDataLoaded = true
    },
  },
})

// Actions
export const {
  updateMmfBarUserData,
  setMmfBarUserData,
} = veMmfSlice.actions

export default veMmfSlice.reducer
