import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import poolsConfig from 'config/constants/pools'
import { BIG_ZERO } from 'utils/bigNumber'
import { PoolsState, SerializedPool, CrocVault, VaultFees, VaultUser, AppThunk } from 'state/types'
import { getPoolApr } from 'utils/apr'
import { getBalanceNumber } from 'utils/formatBalance'
import { fetchPoolsBlockLimits, fetchPoolsStakingLimits, fetchPoolsTotalStaking } from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserStakeBalances,
  fetchUserPendingRewards,
} from './fetchPoolsUser'
import { fetchPublicVaultData, fetchVaultFees } from './fetchVaultPublic'
import fetchVaultUser from './fetchVaultUser'
import { getLpPricesFromFarm, getTokenPricesFromFarm, TokenPriceFromAPI } from './helpers'

const initialState: PoolsState = {
  data: [...poolsConfig],
  userDataLoaded: false,
  cakeVault: {
    totalShares: null,
    pricePerFullShare: null,
    totalCrocInVault: null,
    estimatedCrocBountyReward: null,
    totalPendingCrocHarvest: null,
    fees: {
      performanceFee: null,
      callFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    },
    userData: {
      isLoading: true,
      userShares: null,
      cakeAtLastUserAction: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
    },
  },
}

// Thunks
export const fetchPoolsPublicDataAsync = (currentBlock: number) => async (dispatch, getState) => {
  const blockLimits = await fetchPoolsBlockLimits()
  const totalStakings = await fetchPoolsTotalStaking()

  const farmData = getState().farms.data
  const pricesFromFarm = getTokenPricesFromFarm(farmData)
  const pricesFromLP = getLpPricesFromFarm(farmData.filter((x) => x.pid === 10))

  const mmfPrice = pricesFromFarm['0x97749c9b61f878a880dfe312d2594ae07aed7656']
  const croPrice = pricesFromFarm['0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23']
  const metfPrice = pricesFromFarm['0xb8df27c687c6af9afe845a2afad2d01e199f4878']

  // console.log(pricesFromFarm)
  const tokenPriceMap = await TokenPriceFromAPI(mmfPrice, croPrice, metfPrice)
  const prices = { ...pricesFromFarm, ...tokenPriceMap, ...pricesFromLP }

  const liveData = poolsConfig.map((pool) => {
    const blockLimit = blockLimits.find(
      (entry) => entry.compositeSousId === `${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`,
    )
    const totalStaking = totalStakings.find(
      (entry) => entry.compositeSousId === `${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`,
    )
    const isPoolEndBlockExceeded = currentBlock > 0 && blockLimit ? currentBlock > Number(blockLimit.endBlock) : false
    const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded

    const stakingTokenAddress = pool.stakingToken.address ? pool.stakingToken.address.toLowerCase() : null
    const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

    const earningTokenAddress = pool.earningToken.address ? pool.earningToken.address.toLowerCase() : null
    const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0

    // console.log(stakingTokenPrice, earningTokenPrice)
    const apr = !isPoolFinished
      ? getPoolApr(
          stakingTokenPrice,
          earningTokenPrice,
          getBalanceNumber(new BigNumber(totalStaking.totalStaked), pool.stakingToken.decimals),
          parseFloat(pool.tokenPerBlock),
        )
      : 0

    return {
      ...blockLimit,
      ...totalStaking,
      stakingTokenPrice,
      earningTokenPrice,
      apr,
      isFinished: isPoolFinished,
    }
  })

  dispatch(setPoolsPublicData(liveData))
}

export const fetchPoolsStakingLimitsAsync = () => async (dispatch, getState) => {
  const poolsWithStakingLimit = getState()
    .pools.data.filter(({ stakingLimit }) => stakingLimit !== null && stakingLimit !== undefined)
    .map((pool) => pool.sousId)

  const stakingLimits = await fetchPoolsStakingLimits(poolsWithStakingLimit)

  const stakingLimitData = poolsConfig.map((pool) => {
    if (poolsWithStakingLimit.includes(pool.sousId)) {
      return { sousId: pool.sousId }
    }
    const stakingLimit = stakingLimits[pool.sousId] || BIG_ZERO
    return {
      sousId: pool.sousId,
      isV2: pool.isV2,
      stakingLimit: stakingLimit.toJSON(),
    }
  })

  dispatch(setPoolsPublicData(stakingLimitData))
}

export const fetchPoolsUserDataAsync =
  (account: string, isV2: boolean): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchPoolsAllowance(account)
    const stakingTokenBalances = await fetchUserBalances(account)
    const stakedBalances = await fetchUserStakeBalances(account, isV2)
    const pendingRewards = await fetchUserPendingRewards(account, isV2)
    const userData = poolsConfig.map((pool) => ({
      sousId: pool.sousId,
      isV2: pool.isV2,
      allowance: allowances[`${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`],
      stakingTokenBalance: stakingTokenBalances[`${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`],
      stakedBalance: stakedBalances[`${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`],
      pendingReward: pendingRewards[`${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`],
    }))

    dispatch(setPoolsUserData(userData))
  }

export const updateUserAllowance =
  (sousId: number, account: string, isV2: boolean): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchPoolsAllowance(account)
    dispatch(
      updatePoolsUserData({
        sousId,
        field: 'allowance',
        value: allowances[`${sousId}+${isV2 ? 'V2' : 'V1'}`],
        isV2,
      }),
    )
  }

export const updateUserBalance =
  (sousId: number, account: string, isV2: boolean): AppThunk =>
  async (dispatch) => {
    const tokenBalances = await fetchUserBalances(account)
    dispatch(
      updatePoolsUserData({
        sousId,
        field: 'stakingTokenBalance',
        value: tokenBalances[`${sousId}+${isV2 ? 'V2' : 'V1'}`],
        isV2,
      }),
    )
  }

export const updateUserStakedBalance =
  (sousId: number, account: string, isV2: boolean): AppThunk =>
  async (dispatch) => {
    const stakedBalances = await fetchUserStakeBalances(account, isV2)
    dispatch(
      updatePoolsUserData({
        sousId,
        field: 'stakedBalance',
        value: stakedBalances[`${sousId}+${isV2 ? 'V2' : 'V1'}`],
        isV2,
      }),
    )
  }

export const updateUserPendingReward =
  (sousId: number, account: string, isV2: boolean): AppThunk =>
  async (dispatch) => {
    const pendingRewards = await fetchUserPendingRewards(account, isV2)
    dispatch(
      updatePoolsUserData({
        sousId,
        field: 'pendingReward',
        value: pendingRewards[`${sousId}+${isV2 ? 'V2' : 'V1'}`],
        isV2,
      }),
    )
  }

export const fetchCrocVaultPublicData = createAsyncThunk<CrocVault>('cakeVault/fetchPublicData', async () => {
  const publicVaultInfo = await fetchPublicVaultData()
  return publicVaultInfo
})

export const fetchCrocVaultFees = createAsyncThunk<VaultFees>('cakeVault/fetchFees', async () => {
  const vaultFees = await fetchVaultFees()
  return vaultFees
})

export const fetchCrocVaultUserData = createAsyncThunk<VaultUser, { account: string }>(
  'cakeVault/fetchUser',
  async ({ account }) => {
    const userData = await fetchVaultUser(account)
    return userData
  },
)

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: SerializedPool[] = action.payload
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find(
          (entry) => `${entry.sousId}+${entry.isV2 ? 'V2' : 'V1'}` === `${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`,
        )
        return { ...pool, ...livePoolData }
      })
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((pool) => {
        const userPoolData = userData.find(
          (entry) => `${entry.sousId}+${entry.isV2 ? 'V2' : 'V1'}` === `${pool.sousId}+${pool.isV2 ? 'V2' : 'V1'}`,
        )
        return { ...pool, userData: userPoolData }
      })
      state.userDataLoaded = true
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, sousId, isV2 } = action.payload
      const index = state.data.findIndex((p) => p.sousId === sousId && p.isV2 === isV2)

      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
      }
    },
  },
  extraReducers: (builder) => {
    // Vault public data that updates frequently
    builder.addCase(fetchCrocVaultPublicData.fulfilled, (state, action: PayloadAction<CrocVault>) => {
      state.cakeVault = { ...state.cakeVault, ...action.payload }
    })
    // Vault fees
    builder.addCase(fetchCrocVaultFees.fulfilled, (state, action: PayloadAction<VaultFees>) => {
      const fees = action.payload
      state.cakeVault = { ...state.cakeVault, fees }
    })
    // Vault user data
    builder.addCase(fetchCrocVaultUserData.fulfilled, (state, action: PayloadAction<VaultUser>) => {
      const userData = action.payload
      userData.isLoading = false
      state.cakeVault = { ...state.cakeVault, userData }
    })
  },
})

// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData } = PoolsSlice.actions

export default PoolsSlice.reducer
